.action-library {
  @include PAGE_GRID;
  @include SECTION_CONTENT_SPACE(margin-top);
}

h2 {
  grid-column: 2/14;
}

.tiles {
  grid-column: 2/14;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
  justify-content: space-between;
}

.action-library-tile {
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
  align-items: center;
  justify-items: center;
  box-sizing: border-box;
  padding: 0.5em;
  width: 192px;
  height: 135px;
  position: relative;
  background: $SAND_COLOR;
  border-radius: 12px;
  margin: 0.5rem;
  border: 1px solid transparent;
  display: grid;

  @include RESPOND_FROM($BREAKPOINT_1440) {
    width: 192px;
  }
  &[data-selected="true"] {
    background-color: $SOFT_GREEN_12_COLOR;
    border: 1px solid $SOFT_GREEN_100_COLOR;
  }
  &:hover,
  &:active {
    background-color: $SOFT_GREEN_12_COLOR;
    border: 1px solid $SIEMENS_PETROL_100_COLOR;
  }
  .img-container {
    margin: auto;
    width: fit-content;
  }
  img {
    pointer-events: none;
    width: 45px;
    height: 45px;
    object-fit: contain;
  }
  .h3 {
    @include FOOTNOTE_FONTSTYLE;
    text-align: center;
    line-height: 20px;
  }
}

.library-items {
  grid-column: 2/14;
  margin-top: 40px;
}

.category {
  &.hidden {
    display: none;
  }
}

.category-item {
  @include SECTION_CONTENT_SPACE(padding-top);
  @include SECTION_CONTENT_SPACE(padding-bottom);
  position: relative;
  box-sizing: border-box;
  border-top: 1px solid $DEEP_BLUE_100_COLOR;

  &:last-child {
    border-bottom: 1px solid $DEEP_BLUE_100_COLOR;
  }
  @include RESPOND_FROM($BREAKPOINT_1024) {
    padding-left: 1em;
    padding-right: 0;
  }
  .title {
    font-size: 22px;
    line-height: 28px;
    padding-right: 50px;
    @include RESPOND_FROM($BREAKPOINT_1024) {
      padding-right: 0;
    }
  }
  .content {
    overflow: hidden;
    p {
      @include P_FONTSTYLE;
      overflow: hidden;
    }

    .textLink {
      margin-top: 20px;
    }
  }
}

@media (min-width: 1024px) {
  .giving-feedback-min-height {
    min-height: 100%;
  }
}

.giving-feedback-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
