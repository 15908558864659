/* TABS */

.mat-mdc-tab-list {
  border-bottom: 2px solid $DEEP_BLUE_20_COLOR;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: $HOVER_LINK_COLOR;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: $DEEP_BLUE_100_COLOR;
  font-size: 20px;
  font-family: "Siemens Sans Black", Arial, Helvetica, sans-serif;
}
