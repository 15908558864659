.sgt-illustratedteaser {
  @include PAGE_GRID;
  @include SECTION_SPACE(padding-top);
  @include SECTION_SPACE(padding-bottom);
  gt-animated-illustration .sgt-animated-illustration {
    width: 100%;
    height: 100%;
  }
  &[data-bg="light"] {
    background-color: $WHITE_COLOR;
  }
  &[data-bg="sand"] {
    background-color: $SAND_COLOR;
  }
  &[data-scheme="1/1"] {
    .h2 {
      order: 2;
      grid-column: 2/14;
      text-align: center;
      @include RESPOND_FROM($BREAKPOINT_768) {
        grid-column: 6/10;
        text-align: left;
        align-self: center;
      }
    }
    a,
    gt-custom-link {
      order: 3;
      grid-column: 2/14;
      @include SECTION_CONTENT_SPACE;
      @include RESPOND_FROM($BREAKPOINT_550) {
        grid-column: 6/10;
      }
      @include RESPOND_FROM($BREAKPOINT_768) {
        box-sizing: border-box;
        width: 100%;
        max-width: 240px;
        margin-top: 0;
        grid-column: 10/13;
        align-self: center;
        justify-self: right;
      }
    }
    gt-animated-illustration {
      order: 1;
      grid-column: 2/14;
      @include FLUID_PROPERTY(height, 90, 110, $BREAKPOINT_400, $BREAKPOINT_768);
      @include FLUID_PROPERTY(margin-bottom, 20, 30, $BREAKPOINT_400, $BREAKPOINT_768);
      @include RESPOND_FROM($BREAKPOINT_768) {
        height: 150px;
        max-height: 100%;
        grid-column: 3/6;
        margin-bottom: 0;
        @include FLUID_PROPERTY(margin-right, 20, 30, $BREAKPOINT_768);
        justify-self: stretch;
      }
    }
  }
  &[data-scheme="3/4"] {
    .h2,
    p {
      order: 2;
      grid-column: 2/14;
      text-align: center;
      @include RESPOND_FROM($BREAKPOINT_768) {
        grid-column: 8/14;
        text-align: left;
      }
    }
    a,
    .btns {
      order: 3;
      grid-column: 2/14;
      @include RESPOND_FROM($BREAKPOINT_768) {
        grid-column: 8/11;
      }
    }
    .btns {
      display: flex;
      justify-content: space-between;
      > * {
        margin-right: 40px;
      }
    }
    .info {
      grid-column: 2/14;
    }
    gt-animated-illustration {
      order: 1;
      grid-column: 2/14;
      @include FLUID_PROPERTY(height, 120, 200, $BREAKPOINT_400, $BREAKPOINT_768);
      @include RESPOND_FROM($BREAKPOINT_768) {
        grid-row: 1/3;
        grid-column: 4/8;
        justify-self: stretch;
      }
    }
    &[data-illustration-right="true"] {
      gt-animated-illustration {
        order: 3;
        @include RESPOND_FROM($BREAKPOINT_768) {
          grid-row: 1/3;
          grid-column: 11/14;
          justify-self: stretch;
        }
      }
      .h2,
      p {
        order: 1;
        @include RESPOND_FROM($BREAKPOINT_768) {
          grid-column: 2/10;
        }
      }
      a,
      .btns {
        width: fit-content;
        order: 2;
        @include RESPOND_FROM($BREAKPOINT_768) {
          grid-column: 2/10;
        }
      }
    }
  }
  &[data-scheme="1/4"] {
    .h2 {
      order: 2;
      grid-column: 2/14;
      text-align: center;
      margin-bottom: 0;
      @include RESPOND_FROM($BREAKPOINT_768) {
        grid-column: 3/14;
        text-align: left;
      }
    }
    a,
    p,
    gt-custom-link {
      order: 3;
      grid-column: 2/14;
      @include RESPOND_FROM($BREAKPOINT_768) {
        grid-column: 3/11;
      }
    }
    gt-animated-illustration {
      order: 1;
      grid-column: 2/14;
      @include FLUID_PROPERTY(height, 120, 200, $BREAKPOINT_400, $BREAKPOINT_768);
      @include RESPOND_FROM($BREAKPOINT_768) {
        grid-column: 2/3;
        justify-self: stretch;
      }
    }
  }
  .h2 {
    @include H5_FONTSTYLE;
  }
}
