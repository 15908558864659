.sgt-round-cards {
  @include SECTION_SPACE(padding-top);
  @include SECTION_SPACE(padding-bottom);
  @include PAGE_GRID;
  &[data-scheme="light"] {
    background-color: $WHITE_COLOR;
  }
  &[data-scheme="sand"] {
    background-color: $SAND_COLOR;
  }
  .h2 {
    @include H2_FONTSTYLE;
    grid-column: 2/13;
    display: inline-block;
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 2/13;
    }
    > * {
      @include H2_FONTSTYLE;
    }
  }
  p {
    @include P_FONTSTYLE;
    grid-column: 2/13;
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 2/13;
    }
  }
  .content {
    grid-column: 2/11;
    .h3 {
      @include SUBTITLE_FONTSTYLE;
    }
    > ul {
      @include PAGE_GRID_GAP();
      @include SECTION_CONTENT_SPACE;
      display: grid;
      grid-template-columns: 0 repeat(12, auto) 0;
      > li {
        grid-column: 1/13;
        margin-top: 30px;
        @include RESPOND_FROM($BREAKPOINT_768) {
          &:nth-child(2n + 1) {
            grid-column: 2/ 7;
          }
          &:nth-child(2n) {
            grid-column: 8/ 13;
          }
        }
        @include RESPOND_FROM($BREAKPOINT_1024) {
          padding-left: 0;
          max-width: 365px;
          &:nth-child(4n + 1) {
            grid-column: 2 / span 3;
          }
          &:nth-child(4n + 2) {
            grid-column: 5 / span 3;
          }
          &:nth-child(4n + 3) {
            grid-column: 8 / span 3;
          }
          &:nth-child(4n + 4) {
            grid-column: 11 / span 3;
          }
        }
        box-sizing: border-box;
        img {
          width: 195px * 0.8;
          height: 195px * 0.8;
          display: block;
          margin: auto !important;
          @include RESPOND_FROM($BREAKPOINT_768) {
            margin: unset;
          }
          @include RESPOND_FROM($BREAKPOINT_1024) {
            width: 210px;
            height: 195px;
            @include FLUID_PROPERTY(width, 195 * 0.8, 195, $BREAKPOINT_1024);
            @include FLUID_PROPERTY(height, 195 * 0.8, 195, $BREAKPOINT_1024);
          }
        }
        .h3 {
          text-transform: none;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          @include SECTION_CONTENT_SPACE;
          @include H3_FONTSTYLE;
          &[data-text-align="center"] {
            text-align: center;
            // @include RESPOND_FROM($BREAKPOINT_768) {
            //   text-align: left;
            // }
          }
        }
        p {
          margin-left: auto;
          margin-right: auto;
          width: fit-content;
          min-height: 85px;
          @include FLUID_PROPERTY(margin-top, 20, 30);
          @include P_FONTSTYLE;
        }
        a > * {
          display: flex;
          grid-column: 2 / auto;
          width: 100%;
          justify-content: space-between;
        }
      }

      @include RESPOND_FROM($BREAKPOINT_1024) {
        margin-left: -35px;
      }
      &[data-columns="3"] {
        column-gap: 35px !important;
        grid-column-gap: 35px !important;
        > li {
          grid-column: 1/13;
          @include RESPOND_FROM($BREAKPOINT_400) {
            // padding-left: 1em;
          }
          @include RESPOND_FROM($BREAKPOINT_768) {
            //padding-left: 1.5em;
            // &:nth-child(1) {
            //   margin-top: 0;
            // }
            &:nth-child(2n + 1) {
              grid-column: 2/ 7;
            }
            &:nth-child(2n) {
              grid-column: 8/ 13;
            }
          }
          @include RESPOND_FROM($BREAKPOINT_1024) {
            // padding-left: 2em;
            padding-left: 0;
            max-width: 365px;
            &:nth-child(3n + 1) {
              margin-top: 40px;
              grid-column: 2 / span 4;
            }
            &:nth-child(3n + 2) {
              margin-top: 40px;
              grid-column: 6 / span 4;
            }
            &:nth-child(3n + 3) {
              margin-top: 40px;
              grid-column: 10 / span 4;
            }
          }
        }
      }
    }

    a {
      @include SECTION_CONTENT_SPACE;
      display: flex;
      grid-column: 2 / auto;
      width: 100%;
      justify-content: space-between;

      @include RESPOND_FROM($BREAKPOINT_550) {
        display: inline-flex;
      }
      &.button {
        cursor: pointer;
      }
      &.textLink {
        grid-column: 2 / 6;
        margin-top: 40px;
      }
    }
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 2/15;
    }
    @include RESPOND_FROM($BREAKPOINT_1024) {
      grid-column: 2/11;
    }
    gt-hint {
      grid-column: 2/13;
    }
    &.no-animation {
      grid-column: 2/13;
    }
  }
  .illustration-container {
    grid-column: 11/14;
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 2/14;
    }
    @include RESPOND_FROM($BREAKPOINT_1024) {
      grid-column: 11/14;
    }
  }
  .afterCards-p {
    margin-top: 60px;
  }

  // .animation {
  //   display: none;
  //   @include RESPOND_FROM($BREAKPOINT_1024) {
  //     display: inherit;
  //     grid-column: 11/14;
  //     img {
  //       width: 100%;
  //     }
  //   }
  // }

  gt-animated-illustration.image .sgt-animated-illustration {
    width: 100%;
    height: 100%;
  }

  gt-animated-illustration.image {
    order: 1;
    @include FLUID_PROPERTY(height, 90, 110, $BREAKPOINT_400, $BREAKPOINT_768);
    @include FLUID_PROPERTY(margin-bottom, 20, 30, $BREAKPOINT_400, $BREAKPOINT_768);
    // @include RESPOND_FROM($BREAKPOINT_768) {
    //     height: 150px;
    //     max-height: 100%;
    //     grid-column: 3/6;
    //     margin-bottom: 0;
    //     @include FLUID_PROPERTY(margin-right, 20, 30, $BREAKPOINT_768);
    //     justify-self: stretch;
    // }
  }
}
