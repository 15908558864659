/* ----------------------------------------------------------------
 * BODY
 * --------------------------------------------------------------*/

html {
  overflow-x: hidden; // fix for Safari, cause body.style.overflow-x is ignored > will be removed from JS if not Safari
  width: 100vw;

  &.loading {
    overflow: hidden;
  }
}

body {
  @include P_FONTSTYLE;
  position: relative;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100vw;
  background-color: $WHITE_COLOR;
  color: $DEEP_BLUE_100_COLOR;
  -webkit-text-size-adjust: 100%;
}

/* ----------------------------------------------------------------
 * LISTS
 * --------------------------------------------------------------*/

ul {
  list-style: none;
  margin: 0;
  padding: 0;

  &.bullet-list {
    li {
      position: relative;
      box-sizing: border-box;
      padding-left: 1.3em;

      &:before {
        position: absolute;
        content: " ";
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: $SOFT_GREEN_100_COLOR;
        top: 8px;
        left: 0;
      }
    }
  }
}

/* ----------------------------------------------------------------
 * ANCHOR
 * --------------------------------------------------------------*/

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &.textLink {
    margin-top: 40px;
    display: block;
    color: $LINK_COLOR;
    font-family: "Siemens Sans Black";
    position: relative;
    width: fit-content;
    display: flex !important;

    > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .rearIcon {
      margin-left: 15px;
    }

    .frontIcon {
      margin-right: 15px;
    }

    &:hover {
      text-decoration: none;
      color: $HOVER_LINK_COLOR;
    }
  }
}

.textLink {
  display: block;
  color: $LINK_COLOR;
  font-family: "Siemens Sans Black";
  position: relative;
  width: fit-content;
  display: flex !important;

  > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rearIcon {
    margin-left: 15px;
  }

  .frontIcon {
    margin-right: 15px;
  }

  &:hover {
    text-decoration: none;
    color: $HOVER_LINK_COLOR;
  }
}

/* ----------------------------------------------------------------
 * PARAGRAPH
 * --------------------------------------------------------------*/

p {
  margin-bottom: 0;
}

/* ----------------------------------------------------------------
 * HEADINGS
 * --------------------------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: unset;
  font-weight: unset;
}

.h1 {
  @include H1_FONTSTYLE;
}

.h2 {
  @include H2_FONTSTYLE;
}

.h1,
.h2 {
  margin-bottom: 30px;
}

.h3 {
  @include H3_FONTSTYLE;
}

.h4 {
  @include H3_FONTSTYLE;
}

.heading {
  display: flex;
  align-items: center;
  gap: 10px;
  .heading-text {
    font-weight: bold;
    font-size: 20px;
  }
}

/* ----------------------------------------------------------------
 * Figure
 * --------------------------------------------------------------*/

figure {
  margin: 0;
}

/* ----------------------------------------------------------------
 * Overlay
 * --------------------------------------------------------------*/

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

/* ----------------------------------------------------------------
 * Scribbles
 * --------------------------------------------------------------*/

.scribble-container {
  position: relative;
}
.scribble {
  width: 125px;
  height: 125px;
  &.top-right {
    position: absolute;
    z-index: 2;
    top: 45px;
    right: 0px;
  }
}

.top-right-container {
  position: relative;
  .top-right {
    position: absolute;
    top: -10px;
    right: -40px;
  }
}

/* ----------------------------------------------------------------
 * Flex-containers
 * --------------------------------------------------------------*/

.column-container {
  display: flex;
  flex-direction: column;
  button {
    align-self: center;
  }
}
.row-container {
  display: flex;
  flex-wrap: wrap;
}
.align-center {
  align-items: center;
}
.align-self-center {
  align-self: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.align-start {
  align-items: flex-start;
}
.gap-5 {
  gap: 5px;
}
.gap-10 {
  gap: 10px;
}
.gap-20 {
  gap: 20px;
}
.gap-30 {
  gap: 30px;
}
.gap-50 {
  gap: 50px;
}
.flex-1 {
  flex: 1;
}
.max-30 {
  max-width: 30%;
}
.max-50 {
  max-width: 50%;
}
.margin-top-30 {
  margin-top: 30px;
}
.no-wrap {
  flex-wrap: nowrap;
}
.text-heading {
  font-size: 22px;
  font-weight: bold;
}
.sub-heading {
  font-size: 18px;
  font-weight: bold;
}

.small-heading {
  font-size: 14px;
  font-weight: bold;
}

/* ----------------------------------------------------------------
 * Drop-down
 * --------------------------------------------------------------*/

.selection-form-field {
  height: fit-content;
  flex: 1;
  margin-bottom: -1em;
  .selection {
    .mat-mdc-select-value-text {
      white-space: break-spaces;
    }
  }
  .mat-mdc-form-field-subscript-wrapper {
    height: 0px;
  }
}
