.sgt-centered-card {
  @include PAGE_GRID;
  // background-color: $SAND_COLOR;
  @include SECTION_SPACE(padding-top);
  @include SECTION_SPACE(padding-bottom);

  &[data-scheme="dark"] {
    background-color: $DEEP_BLUE_100_COLOR;
    .sgt-content-section-copy {
      .h2 {
        color: $WHITE_COLOR;
      }
      p {
        color: $WHITE_COLOR;
      }
    }
  }
  &[data-scheme="light"] {
    background-color: $WHITE_COLOR;
    .sgt-content-section-copy {
      h1 {
        color: $DEEP_BLUE_100_COLOR;
      }
      p {
        color: $DEEP_BLUE_100_COLOR;
      }
    }
  }
  &[data-scheme="sand"] {
    background-color: $SAND_COLOR;
    .sgt-content-section-copy {
      .h2 {
        color: $DEEP_BLUE_100_COLOR;
      }
      p {
        color: $DEEP_BLUE_100_COLOR;
      }
    }
  }

  .content {
    grid-column: 3/12;
    text-align: left;
  }

  a:not(gt-custom-link a) {
    @include SECTION_CONTENT_SPACE;
    display: flex;
    @include RESPOND_FROM($BREAKPOINT_550) {
      display: inline-flex;
    }
  }

  .title {
    @include H1_FONTSTYLE;
  }

  .subtitle {
    @include SUBTITLE_FONTSTYLE;
  }

  p {
    @include SECTION_CONTENT_SPACE;
    @include P_FONTSTYLE;
  }

  img {
    width: 100%;
    border-radius: 4px;
    margin-top: 60px;
  }

  .tags-list {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
}
