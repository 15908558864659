.sgt-subnav {
  @include PAGE_GRID;
  @include FLUID_PROPERTY(height, 100, 100);
  align-items: center;
  position: relative;
  &[data-scheme="deepblue"] {
    background: $DEEP_BLUE_100_COLOR;
    > h2 {
      color: $WHITE_COLOR;
      a {
        cursor: pointer;
        &:hover,
        &:active {
          color: $HOVER_LINK_COLOR;
        }
      }
    }
    .sgt-subnav {
      &-menubutton {
        &-show {
          background-image: url("/assets/ui/menu-show-white.svg");
        }
      }
    }
  }
  &[data-scheme="light"] {
    background: $WHITE_COLOR;
    > h2 {
      color: $DEEP_BLUE_100_COLOR;
      a {
        cursor: pointer;
        &:hover,
        &:active {
          color: $HOVER_LINK_COLOR !important;
        }
      }
    }
    .sgt-subnav {
      &-menubutton {
        &-show {
          background-image: url("/assets/ui/menu-show-white.svg");
        }
      }
    }
  }
  &[data-scheme="sand"] {
    background: $DEEP_BLUE_100_COLOR;
    @include RESPOND_FROM($BREAKPOINT_1024) {
      background: $SAND_COLOR;
    }
    > h2 {
      color: $WHITE_COLOR;
      @include RESPOND_FROM($BREAKPOINT_1024) {
        color: $DEEP_BLUE_100_COLOR;
      }
      a {
        &:hover,
        &:active {
          color: $BOLD_BLUE_COLOR;
        }
      }
    }
    .sgt-subnav {
      &-menubutton {
        &-show {
          background-image: url("/assets/ui/menu-show-white.svg");
        }
      }
      &-static {
        ul {
          li {
            a {
              @include MENU_TEXT_LINK;
              @include RESPOND_FROM($BREAKPOINT_1024) {
                @include MENU_TEXT_LINK($DEEP_BLUE_100_COLOR, $SOFT_GREEN_100_COLOR);
              }
            }
          }
          .sgt-menubar-static-current {
            a {
              border-bottom: 2px solid $SOFT_GREEN_100_COLOR;
            }
          }
        }
      }
    }
  }
  &[data-scheme="light"] {
    background: $DEEP_BLUE_100_COLOR;
    @include RESPOND_FROM($BREAKPOINT_1024) {
      background: $WHITE_COLOR;
    }
    > h2 {
      color: $WHITE_COLOR;
      @include RESPOND_FROM($BREAKPOINT_1024) {
        color: $DEEP_BLUE_100_COLOR;
      }
      a {
        &:hover,
        &:active {
          color: $BOLD_BLUE_COLOR;
        }
      }
    }
    .sgt-subnav {
      &-menubutton {
        &-show {
          background-image: url("/assets/ui/menu-show-white.svg");
        }
      }
      &-static {
        ul {
          li {
            a {
              @include MENU_TEXT_LINK;
              @include RESPOND_FROM($BREAKPOINT_1024) {
                @include MENU_TEXT_LINK($DEEP_BLUE_60_COLOR, $DEEP_BLUE_100_COLOR);
              }
            }
            &.active {
              a {
                @include RESPOND_FROM($BREAKPOINT_1024) {
                  color: $DEEP_BLUE_100_COLOR !important;
                }
                font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK !important;
              }
            }
          }
        }
      }
    }
  }
  &-menubutton {
    position: relative;
    width: 55px;
    height: 55px;
    grid-column: 2 / 4;
    z-index: 10001;
    overflow: hidden;
    display: block;
    left: -10px;
    @include RESPOND_FROM($BREAKPOINT_768) {
      display: none;
    }
    &-show {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
    }
    &-close {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      left: -100%;
      background-image: url("/assets/ui/menu-close-deepblue.svg");
      background-repeat: no-repeat;
    }
  }
  > h2 {
    @include MENUBAR_TITLE_FONTSTYLE;
    color: $WHITE_COLOR;
    grid-column: 4 / 10;
    text-align: center;
    position: relative;
    z-index: 1;
    a {
      text-decoration: none;
    }
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 2 / 5;
      text-align: left;
    }
  }
  &-static {
    grid-column: 5 / 10;
    display: none;
    @include RESPOND_FROM($BREAKPOINT_768) {
      display: block;
    }
    ul {
      white-space: nowrap;
      li {
        display: inline-block;
        margin-left: 2em;
        @include FLUID_PROPERTY(margin-left, 20, 60);
        &:first-child {
          margin-left: 0;
        }
        a {
          cursor: pointer;
          @include MENUBAR_ITEM_FONTSTYLE;
          @include MENU_TEXT_LINK;
        }
        &.active {
          border-bottom: 2px solid $BOLD_GREEN_COLOR;
          @include RESPOND_FROM($BREAKPOINT_1024) {
            border-bottom: 2px solid $DEEP_BLUE_100_COLOR;
          }
        }
      }
      .sgt-subnav-static-current {
        a {
          @include MENUBAR_ITEM_FONTSTYLE(false, true);
          padding-bottom: 3px;
          border-bottom: 2px solid $BOLD_GREEN_COLOR;
        }
      }
    }
  }
  &-flyout {
    position: absolute;
    background-color: $WHITE_COLOR;
    width: 100vw;
    max-width: 430px;
    height: 100vh;
    top: 0;
    z-index: 10000;
    transform: translateX(-100%);
    display: block;
    @include RESPOND_FROM($BREAKPOINT_768) {
      display: none;
    }
    &.open {
      transform: translateX(0);
    }
    &:before {
      position: absolute;
      @include FLUID_PROPERTY(height, 100, 100);
      content: " ";
      width: 100%;
      background: $DYNAMIC_PETROL_GRADIENT;
    }
    section {
      width: 100%;
      padding-left: 2em;
      box-sizing: border-box;
      @include FLUID_PROPERTY(padding-top, 100, 100);
      h2 {
        @include H6_FONTSTYLE;
        @include SECTION_CONTENT_SPACE;
      }
      ul {
        @include FLUID_PROPERTY(margin-top, 20, 30);
        li {
          a {
            @include MENUBAR_ITEM_FONTSTYLE(true);
            padding-top: 0.5em;
            padding-bottom: 0.5em;
            margin-left: 1em;
            display: inline-block;
          }
        }
        .sgt-subnav-static-current {
          a {
            @include MENUBAR_ITEM_FONTSTYLE(false, true);
            padding-bottom: 3px;
            border-bottom: 2px solid $BOLD_GREEN_COLOR;
          }
        }
      }
    }
  }
  picture {
    @include FLUID_PROPERTY(width, 65, 85);
    justify-self: end;
    grid-column: 10 / 12;
    img {
      position: relative;
      top: 3px;
      width: 100%;
    }
  }
}
