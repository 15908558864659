.stages-overview,
.dimensions-overview {
  .steps {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
  }

  .step {
    width: 100%;

    img {
      width: 80%;
    }
  }

  @include RESPOND_UNTIL($BREAKPOINT_768) {
    .steps {
      flex-direction: column;

      .step {
        display: flex;
        margin-bottom: 20px;
        width: 100%;
      }

      .content {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      img {
        width: auto;
        max-width: 30%;
      }

      .title {
        height: auto;
      }

      .textLink {
        margin-top: 10px;
      }
    }
  }
}

.srj-wizard {
  min-height: calc(100vh - 100px);
  margin-bottom: 100px;
  @include PAGE_GRID;

  .subtitle {
    @include SUBTITLE_FONTSTYLE();
  }

  .sidenav {
    grid-column: 2/4;
    z-index: 1;

    .wizard-sidenav {
      min-height: unset;
      margin-bottom: 30px;
    }
  }

  .wizard-sidenav {
    margin-top: 50px !important;
  }

  .steps-container {
    grid-column: 4/14;
    margin-top: 15px;

    gt-video-section .sgt-video-section,
    .sgt-content-section {
      grid-template-columns: 30px repeat(12, 1fr) 30px !important;
    }

    .btns-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100;
      gap: 20px;
      margin-top: 40px;
      .disclaimer {
        max-width: 350px;
      }
    }
  }
}

.dimensions-overview-dialog,
.srj-wizard.withoutSideAnim {
  .sgt-content-section {
    padding-top: 0 !important;

    .sgt-content-section-copy {
      padding-right: 1em !important;

      &[data-hasimage="false"] {
        grid-column: 1 / 15 !important;
        padding-bottom: 0 !important;

        &[data-hasillustration="false"] {
          grid-column: 1 / 15 !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }
}

.grading,
.journey-rating {
  mat-radio-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .mat-mdc-radio-button {
    --mdc-radio-disabled-unselected-icon-opacity: 1;
    --mdc-radio-disabled-selected-icon-opacity: 1;

    .mdc-form-field {
      position: relative;

      > label {
        position: absolute;
        left: 10px;
        bottom: -15px;
        width: max-content;
      }
    }

    .mdc-radio .mdc-radio__background .mdc-radio__outer-circle,
    .mdc-radio .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle {
      background-color: white !important;
    }
  }
}

.journey-rating .mat-mdc-radio-button .mdc-form-field label {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  max-width: 65px;
  text-align: center;
  top: 30px;
}

.grading {
  position: relative;
  margin: 30px 0 100px;

  .separator-container {
    position: absolute;
    width: calc(100% - 20px);
    padding: 10px;

    hr {
      border-color: #4c4c68;
    }
  }
}

.journey-rating {
  position: relative;
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .label-wrapper {
    width: 30%;
    display: flex;
    align-items: flex-end;
    padding: 0 10px 10px 0px;

    > label {
      font-size: 16px;
      line-height: 28px;
    }
  }

  .radio-wrapper {
    width: 70%;
    padding-right: 10px;

    mat-radio-group {
      align-items: flex-end;
      width: 100%;

      .options-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        width: 90%;
        min-width: calc(var(--num-options) * 60px);

        .separator-container {
          position: absolute;
          width: 100%;
          padding: 0px 0px 20px 0px;

          hr {
            position: absolute;
            border-color: #4c4c68;
            margin: 0;
            width: calc(100% - (100% / var(--num-options)) * 1);
            left: calc(100% - (100% / var(--num-options)) * (var(--num-options) - 0.5));
          }
        }

        .radio-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: calc(100% / var(--num-options));

          &.middle-radio {
            align-items: center;
            padding: 0 10px;
          }
        }
      }

      .not-applicable-wrapper {
        width: 10%;
        display: flex;
        justify-content: center;
        padding-left: 10px;

        .radio-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
      }

      .radio-button-label {
        font-size: 12px;
        line-height: 14px;
        word-break: break-word;
        text-align: center;
      }
    }
  }
}

.selection-container {
  .item {
    border: 1px solid $DEEP_BLUE_20_COLOR;

    .icon {
      color: $SOFT_GREEN_100_COLOR;
    }
  }

  .selection {
    background-color: $SAND_COLOR;

    .item-placeholder {
      border: 1px solid $DEEP_BLUE_20_COLOR;

      &.filled {
        background-color: $WHITE_COLOR;

        .icon-Question {
          color: $SOFT_GREEN_100_COLOR;
        }
      }
    }
  }
}

.selected-topic {
  border: 1px solid $DEEP_BLUE_20_COLOR;
}

.srj-textarea {
  background-color: #ebf7f8;
  border: 1px solid #4c4c68;
  min-width: 100%;
  min-height: 100px;
  font-family: "Siemens Sans", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-family: 28px;
  color: #4c4c68;
  padding: 16px;
}

.srj-user-input {
  border: 1px solid $DEEP_BLUE_20_COLOR;
  font-size: 14px;
  line-height: 18px;
  padding: 14px 16px;
}

@media (max-width: 1400px) {
  .journey-rating .label-wrapper {
    width: 25%;
  }
  .journey-rating .radio-wrapper {
    width: 75%;
  }
}
