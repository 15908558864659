.tags-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  justify-content: flex-start;

  .tag {
    width: 192px;
    height: 135px;
    border-radius: 12px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;

    .tag__img {
      margin: 0 auto !important;
      height: 64px;

      img {
        margin: 0 !important;
        height: 64px;
      }
    }

    a {
      display: inline-flex;
      margin: 0;
    }

    .tag__text {
      font-family: $FONT_FAMILY_SIEMENS_SANS;
      font-weight: $FONT_WEIGHT_NORMAL;
      color: $LINK_COLOR;
      margin-top: 20px;
      min-height: 56px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &:hover {
        color: $HOVER_LINK_COLOR;
        text-decoration: underline;
      }

      width: 100%;

      &.inactive {
        color: $DEEP_BLUE_100_COLOR;
        text-decoration: none;
        pointer-events: none;
      }
    }
  }
}
