.sgt-strengthcomplete {
  @include PAGE_GRID;
  margin-top: 0;
  @include SECTION_SPACE(padding-top);
  &[data-skip-section-space-at-top-for-mobile="true"] {
    margin-top: 0;
    @include RESPOND_FROM($BREAKPOINT_768) {
      @include SECTION_SPACE;
    }
  }

  .main-tabs {
    grid-column: 2/14;
    display: flex;
    font-family: "Siemens Sans";
    .main-tab {
      border-bottom: 2px solid #ccccd4;
      width: 50%;
      text-align: center;
      cursor: pointer;
      &.active {
        border-bottom: 2px solid #00ffb9;
      }
    }
    a {
      font-family: "Siemens Sans";
      color: $DEEP_BLUE_100_COLOR;
      font-weight: normal;
      display: block;
      padding-bottom: 20px;
      &.active {
        font-weight: bold;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  .h2 {
    grid-column: 2/14;
    @include H2_FONTSTYLE;
    @include RESPOND_FROM($BREAKPOINT_1024);
  }
}

.sgt-strengthcomplete-tab-content {
  .sgt-content-section .sgt-content-section-copy,
  .sgt-video-section .sgt-video-section-copy {
    .h2 {
      @include H3_FONTSTYLE;
    }
  }
}
