.search-section {
  @include PAGE_GRID;
  background-color: $SAND_COLOR;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  min-height: 250px;
  padding: 40px 0;
  position: absolute;
  width: 100%;
  top: 65px;
  left: 0;
  z-index: 99;
  max-height: 70vh;
  overflow: scroll;

  > div {
    grid-column: 2/14;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .search-input-container {
    justify-content: center;
    margin-bottom: 30px;

    .search-input {
      min-width: 50%;
      height: fit-content;

      .mat-mdc-text-field-wrapper {
        background-color: white;
      }

      .mat-mdc-form-field-flex {
        padding: 0 10px;
      }

      .mdc-text-field {
        border-radius: 14px !important;
      }

      .mat-mdc-form-field-infix {
        padding-left: 12px;
      }

      .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
      }

      .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
        border-top-right-radius: 14px;
        border-bottom-right-radius: 14px;
      }

      .mdc-text-field--outlined .mdc-notched-outline {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: #000028;
        }
      }

      .search-input-btn {
        background: transparent;
        border-right: 2px solid #000028;
        color: #000028;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        padding: 5px 12px;
        gap: 4px;
      }
    }
  }

  .recommnedations-column {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .no-results {
    > p:first-child {
      margin-top: 0;
    }
  }

  .search-results {
    grid-column: 4/11;
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .title {
    font-size: 16px;
    font-family: "Siemens Sans Black";
  }

  .search-link {
    display: flex;

    img {
      margin-right: 4px;
    }
  }

  gt-custom-link,
  a {
    color: $LINK_COLOR !important;
    font-size: 16px;
    font-family: "Siemens Sans", Arial, Helvetica, sans-serif;
    padding-left: 8px;
  }

  @include RESPOND_UNTIL($BREAKPOINT_768) {
    .search-recommendations {
      grid-column: 4/11;
    }
  }

  &.dark {
    background-color: $DEEP_BLUE_100_COLOR;
    color: $WHITE_COLOR;
    border-top: none;

    .search-input-container .search-input {
      .mat-mdc-text-field-wrapper {
        background-color: $DEEP_BLUE_100_COLOR;
      }

      .mdc-text-field--outlined .mdc-notched-outline {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: $DISABLED_COLOR;
        }
      }

      .mdc-text-field .mdc-text-field__input {
        caret-color: $WHITE_COLOR;
        color: $WHITE_COLOR;
      }

      .search-input-btn {
        border-right: 2px solid $WHITE_COLOR;
        color: $WHITE_COLOR;
      }
    }

    gt-custom-link,
    a {
      color: $DARK_LINK !important;

      &:hover {
        color: $DARK_LINK_HOVER !important;
      }
    }
  }
}

.search-menu.mat-mdc-menu-panel.mat-mdc-menu-panel {
  margin-top: 12px !important;
  margin-left: -12px !important;
}
