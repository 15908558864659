/* FORM FIELD */
.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #ebf7f8;
  border-radius: 0 !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: $DEEP_BLUE_100_COLOR;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #4c4c68 !important;
}

/* stylelint-disable */
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  /* stylelint-enable */
  border-bottom-color: #4c4c68;
}

.mat-mdc-form-field-focus-overlay {
  background: transparent !important;
}

.mat-focused {
  .mdc-text-field--filled {
    background-color: #d1fff2;

    .mdc-line-ripple::after {
      border-bottom-color: $HOVER_LINK_COLOR;
    }

    .mdc-floating-label {
      color: $HOVER_LINK_COLOR !important;
    }
  }
}

.mdc-text-field--disabled.mdc-text-field--filled {
  .mdc-line-ripple::after {
    border-bottom-color: none;
    background-color: none;
  }

  .mdc-floating-label {
    color: $DEEP_BLUE_40_COLOR !important;
  }
}

.mat-mdc-form-field.mat-form-field-invalid {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #fcccd7;

    .mdc-line-ripple::before,
    .mdc-line-ripple::after {
      border-bottom-color: #b81e31 !important;
    }
  }

  .mdc-floating-label {
    color: #b81e31 !important;
  }
}

.mat-mdc-select-value {
  color: $DEEP_BLUE_100_COLOR;
}

.mat-mdc-select-arrow {
  color: $DEEP_BLUE_100_COLOR !important;
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: $HOVER_LINK_COLOR;
}

.mat-mdc-select-panel {
  box-shadow: none !important;
  border-radius: 0 !important;
  border: 1px solid $HOVER_LINK_COLOR;
  padding: 0 !important;
}

.mat-mdc-option {
  background-color: #d1fff2 !important;
  color: $DEEP_BLUE_100_COLOR !important;
  &.mat-mdc-option-active {
    background-color: #62eec7 !important;
  }

  > * {
    color: $DEEP_BLUE_100_COLOR !important;
  }

  &.mat-mdc-active,
  &:hover {
    background-color: #62eec7 !important;
    color: $DEEP_BLUE_100_COLOR !important;
  }
}

/* CHECKBOX */

.mat-mdc-checkbox .mdc-checkbox__ripple {
  background: transparent !important;
  background-color: transparent !important;
  opacity: 0 !important;
}

.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
  background: transparent !important;
  background-color: transparent !important;
  opacity: 0 !important;
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #4c4c68;
  --mdc-checkbox-selected-icon-color: #d1fff2;
  --mdc-checkbox-selected-hover-icon-color: #d1fff2;
  --mdc-checkbox-selected-focus-icon-color: #d1fff2;
  --mdc-checkbox-selected-pressed-icon-color: #d1fff2;
  --mdc-checkbox-unselected-icon-color: #4c4c68;
  --mdc-checkbox-disabled-unselected-icon-color: $DEEP_BLUE_40_COLOR;
}

/* stylelint-disable */
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  /* stylelint-enable */
  border-color: #4c4c68 !important;
}

/* stylelint-disable */
.mat-mdc-checkbox
  .mdc-checkbox:hover
  .mdc-checkbox__native-control:enabled:not(:checked, :indeterminate, [data-indeterminate="true"])
  ~ .mdc-checkbox__background {
  /* stylelint-enable */
  background-color: "transparent" !important;
  border-radius: 0 !important;
  border-color: $HOVER_LINK_COLOR !important;
}

.mat-mdc-checkbox-frame {
  border-color: $DEEP_BLUE_100_COLOR !important;
  border-radius: 0 !important;
}

.mat-mdc-checkbox label,
.mat-mdc-radio-label-content {
  color: #4c4c68;
  font-size: 18px;
}

/* RADIO BUTTON */

.mat-mdc-radio-button .mat-radio-ripple {
  background: transparent !important;
  background-color: transparent !important;
  opacity: 0 !important;
}

.mat-mdc-radio-button .mat-radio-ripple .mat-ripple-element {
  background: transparent !important;
  background-color: transparent !important;
  opacity: 0 !important;
  transition: none !important;
}

.mat-mdc-radio-button.mat-accent {
  --mat-mdc-radio-checked-ripple-color: transparent;
  --mat-mdc-radio-ripple-color: transparent;
  --mdc-radio-unselected-icon-color: #4c4c68;
  --mdc-radio-unselected-hover-icon-color: $HOVER_LINK_COLOR;
  --mdc-radio-unselected-focus-icon-color: $HOVER_LINK_COLOR;
  --mdc-radio-disabled-unselected-icon-color: #7d8099;
  --mdc-radio-disabled-selected-icon-color: #7d8099;
  --mdc-radio-selected-focus-icon-color: $HOVER_LINK_COLOR;
  --mdc-radio-selected-hover-icon-color: $HOVER_LINK_COLOR;
  --mdc-radio-selected-icon-color: #4c4c68;
  --mdc-radio-selected-pressed-icon-color: #4c4c68;
}

/* stylelint-disable */
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle {
  /* stylelint-enable */
  background-color: transparent;
}

.mat-mdc-radio-button .mdc-form-field {
  color: #4c4c68;
  font-size: 18px;
}

.mat-mdc-radio-button .mdc-radio--disabled + label {
  color: #7d8099;
}
