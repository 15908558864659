.header-container {
  display: flex;
  justify-content: space-between;
  grid-column: 2/14;
}

.sgt-menubar {
  @include PAGE_GRID;
  @include FLUID_PROPERTY(height, 100, 100);
  align-items: center;
  position: relative;
  padding: 10px 0;
  &[data-scheme="deepblue"] {
    background: $DEEP_BLUE_100_COLOR;
    color: $WHITE_COLOR;
    .logoSection {
      color: $WHITE_COLOR;
      a {
        &:hover,
        &:active {
          color: $DARK_GREEN_COLOR;
        }
      }
      .appName .app-menu-btn {
        color: $WHITE_COLOR;
      }
    }
    .sgt-menubar {
      &-menubutton {
        &-show {
          background-image: url("/assets/ui/menu-show-white.svg");
        }
      }
      &-static ul li a.active {
        border-bottom: 2px solid $DARK_LINK;
      }
    }
    .header-icon {
      &.active,
      &:hover {
        color: $DARK_LINK_HOVER;
      }
    }
  }
  &[data-scheme="sand"] {
    background: $DEEP_BLUE_100_COLOR;
    @include RESPOND_FROM($BREAKPOINT_1024) {
      background: $SAND_COLOR;
    }
    .logoSection {
      color: $WHITE_COLOR;
      .desktop {
        display: none;
      }
      .mobile {
        display: flex;
      }
      @include RESPOND_FROM($BREAKPOINT_1024) {
        color: $DEEP_BLUE_100_COLOR;
        .desktop {
          display: flex;
        }
        .mobile {
          display: none;
        }
      }
      a {
        &:hover,
        &:active {
          color: $DARK_GREEN_COLOR;
        }
      }
    }
    .sgt-menubar {
      &-menubutton {
        &-show {
          background-image: url("/assets/ui/menu-show-white.svg");
        }
      }
      &-static {
        ul {
          li {
            a {
              @include MENU_TEXT_LINK;
              @include RESPOND_FROM($BREAKPOINT_1024) {
                @include MENU_TEXT_LINK($DEEP_BLUE_100_COLOR, $SOFT_GREEN_100_COLOR);
              }
            }
            &.active {
              border-bottom: 2px solid $SOFT_GREEN_100_COLOR;
            }
          }
        }
      }
      &-admin {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        width: 100%;
      }
    }
  }
  .logoSection {
    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
    }
    @include RESPOND_FROM($BREAKPOINT_1024) {
      .desktop {
        display: flex;
      }
      .mobile {
        display: none;
      }
    }
  }
  &-menubutton {
    position: relative;
    width: 55px;
    height: 55px;
    z-index: 10001;
    overflow: hidden;
    display: block;
    left: -10px;
    @include RESPOND_FROM($BREAKPOINT_1024) {
      display: none;
    }
    &-show {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
    }
    &-close {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      left: -100%;
      background-image: url("/assets/ui/menu-close-deepblue.svg");
      background-repeat: no-repeat;
    }
  }
  .logoSection {
    @include MENUBAR_TITLE_FONTSTYLE;
    color: $WHITE_COLOR;
    text-align: center;
    position: relative;
    display: flex;
    z-index: 1;
    justify-content: space-between;
    flex-direction: row-reverse;
    > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .logo {
      margin-right: 20px;
      img {
        width: 120px;
      }
    }
    .intra {
      margin-right: 20px;
    }
    a {
      text-decoration: none;
    }

    .appName {
      .app-menu-btn {
        padding: 0 5px;
        background: transparent;
        font-size: 16px;
        color: $WHITE_COLOR;
        display: flex;
        flex-direction: row;
        font-family: "Siemens Sans";
        white-space: pre;

        > *:not(.text) {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .text {
          display: block;
        }
      }
    }
    @include RESPOND_FROM($BREAKPOINT_1024) {
      justify-content: flex-start;
      flex-direction: row;
      .appName .app-menu-btn {
        color: $DEEP_BLUE_100_COLOR;
      }
    }
  }
  &-static {
    text-align: right;
    display: none;
    max-width: 600px;
    @include RESPOND_FROM($BREAKPOINT_1024) {
      display: block;
      flex-grow: 1;
    }
    ul {
      white-space: nowrap;
      display: flex;
      gap: 2em;
      li {
        display: inline-block;
        white-space: break-spaces;
        max-width: 230px;
        width: fit-content;
        vertical-align: top;
        text-align: center;
        // @include FLUID_PROPERTY(margin-left, 20, 60);
        a {
          cursor: pointer;
          @include MENUBAR_ITEM_FONTSTYLE;
          @include MENU_TEXT_LINK;
          font-family: "Siemens Sans Black", Arial, Helvetica, sans-serif;
        }
        &.active {
          a {
            border-bottom: 2px solid #00ffb9;
          }
        }
      }
      .sgt-menubar-static-current {
        a {
          @include MENUBAR_ITEM_FONTSTYLE(false, true);
          padding-bottom: 3px;
          border-bottom: 2px solid $BOLD_GREEN_COLOR;
        }
      }
    }
  }
  &-flyout {
    position: absolute;
    background-color: $WHITE_COLOR;
    width: 100vw;
    max-width: 430px;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10000;
    transform: translateX(-100%);
    display: block;
    @include RESPOND_FROM($BREAKPOINT_1024) {
      display: none;
    }
    &.open {
      transform: translateX(0);
    }
    &:before {
      position: absolute;
      @include FLUID_PROPERTY(height, 100, 100);
      content: " ";
      width: 100%;
      background: $DYNAMIC_PETROL_GRADIENT;
    }
    section {
      width: 100%;
      padding-left: 2em;
      box-sizing: border-box;
      @include FLUID_PROPERTY(padding-top, 100, 100);
      .logoSection {
        @include H6_FONTSTYLE;
        @include SECTION_CONTENT_SPACE;
      }
      ul {
        @include FLUID_PROPERTY(margin-top, 20, 30);
        li {
          a {
            @include MENUBAR_ITEM_FONTSTYLE(true);
            padding-top: 0.5em;
            padding-bottom: 0.5em;
            margin-left: 1em;
            display: inline-block;
            &.active {
              @include MENUBAR_ITEM_FONTSTYLE(false, true);
              padding-bottom: 3px;
              border-bottom: 2px solid $BOLD_GREEN_COLOR;
            }
          }
        }
      }
    }
  }
  .header-icon {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    width: fit-content;
    > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 20px;
    }
    &:hover,
    &.active {
      color: #005159;
    }
  }

  .header-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-grow: 0;
    gap: 8px;
  }

  .admin-link {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: #005159;
    }
  }

  .admin-role {
    background-color: $BLUE_COLOR;
    border-radius: 99999px;
    color: white;
    font-size: 16px;
    height: 20px;
    line-height: 16px;
    padding: 4px 16px;
    display: none;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    white-space: nowrap;

    @include RESPOND_FROM($BREAKPOINT_1280) {
      display: flex;
    }
  }

  .no-underline {
    display: flex;

    &:hover {
      text-decoration: none;
    }
  }
  a {
    color: $DEEP_BLUE_100_COLOR;
  }
}

.mat-mdc-menu-item.app-menu-item {
  .mdc-list-item__primary-text {
    display: block;
    span {
      white-space: pre;
    }
    .icon {
      font-weight: bold;
    }
  }
  &:not(.active) .mdc-list-item__primary-text {
    padding-left: 20px;
    .icon {
      display: none;
    }
  }
}

.mat-menu-btn {
  font-family: "Siemens Sans Black", Arial, Helvetica, sans-serif;

  &:hover {
    text-decoration: none;
  }
}

.mat-mdc-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: #f3f3f0;
}
.mat-mdc-menu-item:hover:not([disabled]) {
  background: #e8e8e3;
}
.mat-mdc-menu-item {
  font-size: 16px;
  color: $DEEP_BLUE_100_COLOR;
  .mdc-list-item__primary-text {
    font-family: "Siemens Sans" !important;
  }
  &.active {
    background: #e8e8e3;
  }
}

.dark {
  .mat-mdc-menu-content:not(:empty) {
    background-color: $DEEP_BLUE_80_COLOR;
  }
  .mat-mdc-menu-item:hover:not([disabled]) {
    background: #37374d;
  }
  .mat-mdc-menu-item {
    color: $WHITE_COLOR;
    .mdc-list-item__primary-text {
      font-size: 16px;
      color: $WHITE_COLOR;
    }
    &.active {
      background: $DEEP_BLUE_80_COLOR;
    }
  }
}

.langSwitchPanel,
.appMenu {
  margin-top: 10px;
}
