@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "/src/styles/ui/icons.scss";
@import "utils/utils";
@import "size/size";
@import "color/color";
@import "font/fonts";
@import "ui/buttons";
@import "ui/links";
@import "generic/generic";
@import "ui/input";
@import "ui/tabs";
@import "ui/list";
@import "components/sections/Animation.scss";
@import "components/sections/MenuBar";
@import "components/sections/SubNavigation.scss";
@import "components/sections/GenericTeaser";
@import "components/sections/Testemonials";
@import "components/sections/PageFooter";
@import "components/sections/SubPageHero";
@import "components/sections/ExternalTeasers";
@import "components/sections/Resources";
@import "components/sections/Faq";
@import "components/sections/HowItWorks";
@import "components/sections/StrengthComplete";
@import "components/sections/IllustratedTeaser";
@import "components/sections/WhyItMattersKeyElements.scss";
@import "components/sections/RoundCards.scss";
@import "components/sections/CenteredCard.scss";
@import "components/sections/ContentSection.scss";
@import "components/sections/VideoSection.scss";
@import "components/sections/RoundListCard.scss";
@import "components/sections/TilesList.scss";
@import "components/sections/myGrowthHomeTeasers.scss";
@import "components/sections/WhatsNext.scss";
@import "components/sections/LearningPartners.scss";
@import "components/sections/Hint.scss";
@import "components/sections/Tags.scss";
@import "components/sections/Calendar.scss";
@import "components/sections/Carousel.scss";
@import "components/sections/LessonsLearned.scss";
@import "components/sections/Dialogs.scss";
@import "components/sections/Admin.scss";
@import "components/sections/Search.scss";
@import "components/sections/Brandbuilder.scss";
@import "components/sections/Feedback.scss";
@import "components/sections/StrengthsExperts.scss";
@import "components/sections/PeopleHighlightQuote.scss";
@import "components/sections/SelfReflectionJourney.scss";
@import "components/sections/WizardSideNav.scss";
@import "components/sections/SelfReflectionTab";

.h3 {
  @include H3_FONTSTYLE;
}
.image-section {
  @include FLUID_PROPERTY(padding-top, 30, 60);
  @include FLUID_PROPERTY(padding-bottom, 30, 60);
  @include PAGE_GRID;
  .h2 {
    @include H2_FONTSTYLE;
    grid-column: 2/13;
    display: inline-block;
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 2/13;
    }
    > * {
      @include H2_FONTSTYLE;
    }
  }
  p {
    @include P_FONTSTYLE;
    grid-column: 2/13;
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 2/13;
    }
  }
}

.cls-1 {
  stroke-width: 4px !important;
}

.growthtalksimage {
  background-image: url(/assets/images/bubble_visual_desktop_en.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 600px;
  width: 70%;
  max-width: 1300px;
  margin: auto;

  @media (max-width: 1200px) {
    background-image: url(/assets/images/bubble_visual_mobile_en.jpg);
    height: 1300px;
    width: auto;
  }

  &.fr-ca {
    background-image: url(/assets/images/bubble_visual_desktop_fr.png);

    @media (max-width: 1200px) {
      background-image: url(/assets/images/bubble_visual_mobile_fr.png);
    }
  }

  &.de {
    background-image: url(/assets/images/bubble_visual_desktop_de.png);

    @media (max-width: 1200px) {
      background-image: url(/assets/images/bubble_visual_mobile_de.png);
    }
  }

  &.zh {
    background-image: url(/assets/images/bubble_visual_desktop_zh.png);

    @media (max-width: 1200px) {
      background-image: url(/assets/images/bubble_visual_mobile_zh.png);
    }
  }

  &.es {
    background-image: url(/assets/images/bubble_visual_desktop_es.png);

    @media (max-width: 1200px) {
      background-image: url(/assets/images/bubble_visual_mobile_es.png);
    }
  }

  &.pt-br {
    background-image: url(/assets/images/bubble_visual_desktop_pt-br.png);

    @media (max-width: 1200px) {
      background-image: url(/assets/images/bubble_visual_mobile_pt-br.png);
    }
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $DARK_GREEN_COLOR;
}

.loading-indicator {
  .mat-mdc-progress-spinner circle,
  .mat-spinner circle {
    stroke: $SOFT_GREEN_100_COLOR;
  }
}

.strong,
b {
  font-family: "Siemens Sans Black" !important;
}

.highlight {
  font-family: "Siemens Sans Black";
}

.playlists-inspiration {
  @include PAGE_GRID;
  @include SECTION_SPACE(margin-bottom);

  h2,
  p {
    grid-column: 2/14;
  }

  .tiles-container {
    grid-column: 2/14;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;
  }

  .tile {
    display: flex;
    flex-shrink: 0;
    margin-top: 20px;

    img {
      margin-right: 30px;
      width: 73px;
      height: 73px;
    }

    a {
      margin-top: 10px;
    }
  }
}
