/* You can add global styles to this file, and also import other style files */
@import "styles/index";

html {
  scroll-behavior: smooth;
}

span {
  // white-space: pre;
}

a {
  font-family: "Siemens Sans Black";
  color: $LINK_COLOR;

  &.inline {
    margin-top: unset !important;
    display: inline !important;
  }
}

.span-link-color {
  color: $LINK_COLOR;
}

.principles-download-link {
  cursor: pointer;
}

.span-text-bold {
  font-weight: bold;
}

.span-text-underline {
  text-decoration: underline;
}

.width-max-content {
  width: max-content;
}

.mat-expansion-panel {
  box-shadow: none !important;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.feedback-cards {
  @include PAGE_GRID;

  .cards {
    grid-column: 2/14;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .card {
    width: 45%;
    margin-bottom: 100px;

    .title {
      height: 56px;
      margin-top: 30px;
    }

    a {
      color: #00819c;
      cursor: pointer;
      font-family: "Siemens Sans Black";

      &:hover {
        text-decoration: underline;
      }
    }

    ul {
      padding-left: 30px;

      li {
        @include CUSTOM_LIST_ICONS;
      }
    }
  }
}

.grid {
  @include PAGE_GRID;
}

.sie-tooltip {
  .mdc-tooltip__surface {
    --mdc-plain-tooltip-supporting-text-color: #ffffff;
    --mdc-plain-tooltip-container-color: #000028;
    font-size: 12px;
    padding: 16px;
  }
}

.calendarTooltip {
  .mdc-tooltip__surface {
    padding: 10px;
    font-size: 12px;
    border-radius: 0 !important;
    transform: none !important;
    color: #01002a !important;
    background-color: #00bedc;
  }
  /*
  &:after{
    content: "";
    position: absolute;
    bottom: -10px;
    left: 70%;
    margin-left: -15px;
    border-width: 15px;
    border-style: solid;
  }
*/
  /*
  &:after{
    border-color: #00BEDC transparent transparent transparent;
  }
*/
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: $SAND_COLOR !important;
  height: 80px;
  min-width: 500px !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: #01002a !important;
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: #01002a !important;
}

.survey-stepper {
  font-family: "Siemens Sans", Arial, Helvetica, sans-serif;
  color: $DEEP_BLUE_100_COLOR;
  .mat-horizontal-stepper-header-container {
    display: none;
  }
  .mat-horizontal-content-container {
    padding: 0;
  }
}

.survey-radioGroup {
  display: flex;
  flex-direction: column;
}

.surveyDialog {
  max-width: 800px !important;
  width: 50vw;
}

.mat-pseudo-checkbox-checked {
  border-color: #005159 !important;
  background-color: #ffffff !important;

  &::after {
    color: #005159 !important;
  }
}
