.sgt-why-it-matters-key-elements {
  background-color: $DEEP_BLUE_100_COLOR;
  color: $WHITE_COLOR;
  @include SECTION_SPACE;
  padding: 145px 0px;

  @media (min-width: 400px) {
    padding: calc(60px + 85 * (100vw - 400px) / 1040) 0px;
  }

  .section-content {
    width: 80%;
    margin: auto;
  }

  .h2 {
    margin-bottom: 70px;
    @include H2_FONTSTYLE($color: $WHITE_COLOR);
  }
  .h3 {
    @include H3_FONTSTYLE($color: $WHITE_COLOR);
    margin: 45px 0;
  }
}
