@mixin WHITE_TEXT_LINK() {
  text-decoration: none !important;
  color: $WHITE_COLOR;
  box-sizing: border-box;
  border-bottom: 1px solid transparent;
  &:hover,
  &:active {
    color: $BOLD_GREEN_COLOR;
    border-bottom: 1px solid $BOLD_GREEN_COLOR;
  }
}

@mixin MENU_TEXT_LINK($color: $WHITE_COLOR, $hoverColor: $BOLD_GREEN_COLOR) {
  text-decoration: none !important;
  color: $color;
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
  // transition: all 400ms ease-in-out;
  padding-bottom: 3px;
  &:hover,
  &:active {
    // color: $hoverColor;
    border-bottom: 2px solid $hoverColor;
  }
}

@mixin EXTERNAL_TEXT_LINK {
  @include P_FONTSTYLE;
  font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
  color: $WHITE_COLOR;
  text-decoration: none !important;
  border-bottom: 2px solid transparent;
  position: relative;
  display: flex;
  width: fit-content;
  .icon {
    font-weight: bold;
    margin-left: 20px;
  }
  > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &:hover,
  &:active {
    color: $BOLD_GREEN_COLOR;
    border-bottom: 2px solid $BOLD_GREEN_COLOR;
  }
}

@mixin TEXT_DOWNLOAD_LINK($color: $BLUE_COLOR, $hoverColor: $SOFT_BLUE_100_COLOR) {
  @include P_FONTSTYLE;
  display: inline-block;
  font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
  color: $color;
  text-decoration: none !important;
  border-bottom: 2px solid transparent;
  position: relative;
  &:hover,
  &:active {
    color: $hoverColor;
  }
}

.text-link {
  color: #007993;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    color: #005159;
    text-decoration: underline;
  }

  &.disabled {
    color: #b3b3be;
    pointer-events: none;
  }

  &.bold {
    font-family: "Siemens Sans Black";
  }
}
