.sgt-footer {
  background-color: $DEEP_BLUE_100_COLOR;
  @include SECTION_LARGE_CONTENT_SPACE(padding-top);
  @include SECTION_LARGE_CONTENT_SPACE(padding-bottom);
  @include PAGE_GRID;
  @include PAGE_GRID_GAP(row-gap);

  @include RESPOND_FROM($BREAKPOINT_1440) {
    grid-template-columns: 1fr repeat(12, 90px) 1fr;
    column-gap: 22px;
  }

  align-items: center;

  p {
    margin: 0;
  }

  &-global {
    display: flex;
    justify-content: space-between;
    @include FOOTNOTE_FONTSTYLE;
    grid-column: 2/11;
    order: 1;

    button {
      background: none;
      padding: 0;
      @include WHITE_TEXT_LINK;
      display: flex;

      > span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 5px;
      }

      .icon {
        font-size: 20px;
        padding-bottom: 3px;
      }
    }

    .lang-switcher {
      h4 {
        color: $WHITE_COLOR;
      }

      span {
        cursor: pointer;
        color: $WHITE_COLOR;
      }

      .lang-active {
        border-bottom: 2px solid #00d7a0;
        font-family: "Siemens Sans Black";
      }
    }
  }

  &-text {
    color: $WHITE_COLOR;
    grid-column: 2/11;
    order: 2;
    font-size: 16px;

    a {
      color: $SOFT_GREEN_100_COLOR;
    }
  }

  &-dmt {
    color: $WHITE_COLOR;
    grid-column: 11/14;
    order: 1;
    text-align: right;
    font-size: 14px;
  }

  &-copyright {
    @include FOOTNOTE_FONTSTYLE;
    order: 4;
    grid-column: 2/11;
    color: $WHITE_COLOR;
    @include SECTION_CONTENT_SPACE(padding-top);

    @include RESPOND_FROM($BREAKPOINT_550) {
      padding-top: 0;
      order: 3;
    }

    @include RESPOND_FROM($BREAKPOINT_1280) {
      grid-column: 2/4;
    }
  }

  &-links {
    grid-column: 2/14;
    display: block;
    order: 2;
    margin-top: 1em;
    margin-bottom: 2em;

    @include RESPOND_FROM($BREAKPOINT_768) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include RESPOND_FROM($BREAKPOINT_1280) {
      order: 3;
      margin: 0;
      grid-column: 4/11;
    }

    li {
      margin-top: 1em;

      @include RESPOND_FROM($BREAKPOINT_768) {
        margin-top: 0;
      }

      @include RESPOND_FROM($BREAKPOINT_1280) {
        margin-right: 2em;
      }

      &:last-child {
        margin-right: 0;
      }

      button {
        @include FOOTNOTE_FONTSTYLE;
        @include WHITE_TEXT_LINK;
        background: none;
        padding: 0;
      }
    }
  }
}
