.sgt-video-section {
  @include PAGE_GRID;
  @include SECTION_CONTENT_SPACE(padding-top);
  position: relative;
  &__download-button {
    display: flex;
    justify-content: flex-end;
  }
  &.marginBottom {
    @include SECTION_SPACE(margin-bottom);
  }
  &[data-scheme="dark"] {
    background-color: $DEEP_BLUE_100_COLOR;
    .sgt-video-section-copy {
      .h2 {
        color: $WHITE_COLOR;
      }
      p {
        color: $WHITE_COLOR;
      }
    }
  }
  &[data-scheme="light"] {
    background-color: $WHITE_COLOR;
    .sgt-video-section-copy {
      h1 {
        color: $DEEP_BLUE_100_COLOR;
      }
      p {
        color: $DEEP_BLUE_100_COLOR;
      }
    }
  }
  &[data-scheme="sand"] {
    background-color: $SAND_COLOR;
    .sgt-video-section-copy {
      .h2 {
        color: $DEEP_BLUE_100_COLOR;
      }
      p {
        color: $DEEP_BLUE_100_COLOR;
      }
    }
  }
  &[data-illustration-mobile-position="right-to-title"] {
    @include RESPOND_UNTIL($BREAKPOINT_550) {
      figure,
      .animation {
        grid-column: 10 / 13;
        order: 2;
        position: absolute;
        top: 3%;
        left: 0;
        @include FLUID_PROPERTY(width, 400, 450, $BREAKPOINT_320, $BREAKPOINT_550);
      }
      .sgt-video-section-copy {
        @include SECTION_SPACE(margin-bottom);
        .h1,
        .h2 {
          max-width: 70%;
        }
      }
    }
  }
  &[data-illustration-mobile-position="below-desc"] {
    @include RESPOND_UNTIL($BREAKPOINT_550) {
      figure,
      .animation {
        grid-column: 2 / 14;
        @include SECTION_LARGE_CONTENT_SPACE;
      }
    }
  }
  &[data-image-position-when-next-to-copy="right"] {
    @include RESPOND_FROM($BREAKPOINT_768) {
      .illustration {
        grid-column: 2/14;
      }
    }
    @include RESPOND_FROM($BREAKPOINT_1024) {
      .sgt-video-section-copy {
        @include SECTION_SPACE(padding-bottom);
        // @include PAGE_GRID_FULL;
        margin-bottom: 0 !important;
        grid-column: 2/9;
        padding-right: 3em;
        box-sizing: border-box;
        h1,
        h4,
        p {
          grid-column: 2/14;
        }

        &[data-hasimage="false"] {
          grid-column: 2/12 !important;
          p {
            max-width: unset;
          }
        }
      }
      figure {
        grid-column: 9/14 !important;
      }
      .illustration {
        grid-column: 12/14;
      }
    }
  }
  &[data-image-position-when-next-to-copy="left"] {
    @include RESPOND_FROM($BREAKPOINT_1024) {
      figure,
      .animation {
        order: 1;
        align-self: end;
        &[data-size="normal"] {
          grid-column: 1/9;
        }
        &[data-size="reducedForPageNavigation"] {
          grid-column: 1/9;
        }
        &[data-image-alingment="start"] {
          align-self: start;
        }
        &[data-image-alingment="center"] {
          align-self: center;
        }
        &[data-image-alingment="end"] {
          align-self: end;
        }
      }
      .illustration {
        grid-column: 2/6;
      }
      .sgt-video-section-copy {
        @include SECTION_SPACE(margin-bottom);
        grid-column: 9/14;
        order: 2;

        &[data-hasimage="false"] {
          grid-column: 7/14 !important;
          p {
            max-width: unset;
          }
        }
      }
      &[illustration-size="small"] {
        .illustration,
        figure {
          grid-column: 2/4 !important;
        }
        .sgt-video-section-copy {
          &[data-hasimage="false"] {
            grid-column: 4/14 !important;
          }
        }
      }
      &[illustration-size="xsmall"] {
        .illustration,
        figure {
          grid-column: 2/3 !important;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
        }
        .sgt-video-section-copy {
          grid-column: 3/14 !important;
        }
      }
    }
    @include RESPOND_FROM($BREAKPOINT_1440) {
      figure {
        &[data-size="reducedForPageNavigation"] {
          grid-column: 2/9;
        }
      }
    }
  }
  &[data-image-position-when-below-copy="left"] {
    @include RESPOND_FROM($BREAKPOINT_550) {
      figure {
        position: relative;
        grid-column: 1/12;
        width: 100%;
        top: unset;
        right: unset;
      }
      .sgt-video-section-copy {
        @include SECTION_SPACE(margin-bottom);
      }
    }
  }
  &[data-image-position-when-below-copy="right"] {
    @include RESPOND_FROM($BREAKPOINT_550) {
      figure,
      .animation {
        position: relative;
        grid-column: 4/15;
        width: 100%;
        top: unset;
        right: unset;
      }
      .sgt-video-section-copy {
        @include SECTION_SPACE(margin-bottom);
      }
    }
  }
  a:not(.inline) {
    margin-top: 30px;
    display: block;
    width: fit-content;
  }
  .sgt-video-section-copy {
    @include SECTION_SMALL_CONTENT_SPACE;
    position: relative;
    z-index: 2;
    grid-column: 2/14;
    box-sizing: border-box;
    .subtitle {
      @include SUBTITLE_FONTSTYLE;
    }
    .h2 {
      @include H2_FONTSTYLE;
      font-size: 30px;
      &[data-small-title="true"] {
        max-width: 700px;
      }
    }
    .h1 {
      @include H1_FONTSTYLE;
    }
    .h4 {
      @include H4_FONTSTYLE;
    }
    p {
      @include P_FONTSTYLE;
    }

    ul {
      margin-left: 1em;
    }

    ul.green {
      li {
        margin: 10px 0;
      }
      li::before {
        content: "\2022";
        color: $SOFT_GREEN_100_COLOR;
        font-family: "Siemens Sans Black";
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
    ul.black {
      margin-left: 25px;
      li {
        margin: 10px 0;
      }
      li::before {
        content: "\2022";
        color: black;
        font-family: "Siemens Sans Black";
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
  figure,
  .animation {
    position: relative;
    z-index: 1;
    line-height: 0;
    align-self: end;
    &[data-image-alingment="start"] {
      align-self: start;
    }
    &[data-image-alingment="center"] {
      align-self: center;
    }
    &[data-image-alingment="end"] {
      align-self: end;
    }
    @include RESPOND_UNTIL($BREAKPOINT_550) {
      max-width: 350px;
      svg-component {
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
      }
    }
    img {
      width: 100%;
      visibility: hidden;
      @include RESPOND_FROM($BREAKPOINT_550) {
        visibility: visible;
      }
    }
  }
}

.leaders {
  .sgt-video-section .sgt-video-section-copy .subtitle {
    @include SUBTITLE_FONTSTYLE($PURPLE);
  }
}
