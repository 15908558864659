/* ----------------------------------------------------------------
 * BREAKPOINTS
 * --------------------------------------------------------------*/

$BREAKPOINT_320: 320;
$BREAKPOINT_400: 400;
$BREAKPOINT_550: 550;
$BREAKPOINT_768: 768;
$BREAKPOINT_1024: 1024;
$BREAKPOINT_1280: 1280;
$BREAKPOINT_1440: 1440;
$BREAKPOINT_2560: 2560;

/* ----------------------------------------------------------------
 * GRID
 * --------------------------------------------------------------*/

@mixin PAGE_GRID {
  display: grid;
  grid-template-columns: 0 repeat(12, 1fr) 0;
  @include RESPOND_FROM($BREAKPOINT_400) {
    grid-template-columns: 5px repeat(12, 1fr) 5px;
  }
  @include RESPOND_FROM($BREAKPOINT_1024) {
    grid-template-columns: 30px repeat(12, 1fr) 30px;
  }
  @include RESPOND_FROM($BREAKPOINT_1440) {
    grid-template-columns: 1fr repeat(12, 80px) 1fr;
  }
  @include PAGE_GRID_GAP();
}

@mixin PAGE_GRID_GAP($property: column-gap, $negative: false) {
  @if $negative {
    @include FLUID_PROPERTY($property, -16, -32);
  } @else {
    @include FLUID_PROPERTY($property, 16, 32);
  }
}

@mixin USE_2_COLUMNS {
  &:nth-child(2n + 1) {
    grid-column: 2 / span 6;
  }
  &:nth-child(2n + 2) {
    grid-column: 8 / span 6;
  }
}

@mixin USE_3_COLUMNS {
  &:nth-child(3n + 1) {
    grid-column: 2 / span 4;
  }
  &:nth-child(3n + 2) {
    grid-column: 6 / span 4;
  }
  &:nth-child(3n + 3) {
    grid-column: 10 / span 4;
  }
}

@mixin USE_4_COLUMNS {
  &:nth-child(4n + 1) {
    grid-column: 2 / span 3;
  }
  &:nth-child(4n + 2) {
    grid-column: 5 / span 3;
  }
  &:nth-child(4n + 3) {
    grid-column: 8 / span 3;
  }
  &:nth-child(4n + 4) {
    grid-column: 11 / span 3;
  }
}

@mixin PAGE_MARGIN_IF_NOT_USING_GRID($propLeft: margin-left, $propRight: margin-right) {
  @include FLUID_PROPERTY($propLeft, 16, 16, $BREAKPOINT_320, $BREAKPOINT_400);
  @include FLUID_PROPERTY($propRight, 16, 16, $BREAKPOINT_320, $BREAKPOINT_400);
  @include RESPOND_FROM($BREAKPOINT_400) {
    @include FLUID_PROPERTY($propLeft, 20, 30, $BREAKPOINT_400, $BREAKPOINT_1024);
    @include FLUID_PROPERTY($propRight, 20, 30, $BREAKPOINT_400, $BREAKPOINT_1024);
  }
  @include RESPOND_FROM($BREAKPOINT_1024) {
    @include FLUID_PROPERTY($propLeft, 53, 63, $BREAKPOINT_1024);
    @include FLUID_PROPERTY($propRight, 53, 63, $BREAKPOINT_1024);
  }
  @include RESPOND_FROM($BREAKPOINT_1440) {
    @include FLUID_PROPERTY($propLeft, 53, 622, $BREAKPOINT_1440, $BREAKPOINT_2560);
    @include FLUID_PROPERTY($propRight, 53, 622, $BREAKPOINT_1440, $BREAKPOINT_2560);
  }
}

/* ----------------------------------------------------------------
 * SPACES
 * --------------------------------------------------------------*/

@mixin SECTION_SMALL_CONTENT_SPACE($property: margin-top) {
  @include FLUID_PROPERTY($property, 20, 30);
}

@mixin SECTION_CONTENT_SPACE($property: margin-top) {
  @include FLUID_PROPERTY($property, 35, 45);
}

@mixin SECTION_LARGE_CONTENT_SPACE($property: margin-top) {
  @include FLUID_PROPERTY($property, 45, 70);
}

@mixin SECTION_SPACE($property: margin-top) {
  @include FLUID_PROPERTY($property, 30, 60);
}
