.sgt-round-list-card {
  @include SECTION_SPACE(padding-top);
  @include SECTION_SPACE(padding-bottom);
  @include PAGE_GRID;
  &[data-scheme="light"] {
    background-color: $WHITE_COLOR;
  }
  &[data-scheme="sand"] {
    background-color: $SAND_COLOR;
  }
  .h2 {
    grid-column: 2/14;
    display: inline-block;
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 2/12;
    }
    > * {
      @include H2_FONTSTYLE;
    }
  }
  p {
    @include P_FONTSTYLE;
    grid-column: 2/14;
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 2/12;
    }
  }
  a {
    grid-column: 2/14;
    @include SECTION_CONTENT_SPACE;
  }
  > ul {
    @include PAGE_GRID;
    //  @include SECTION_CONTENT_SPACE;
    grid-column: 1/15;
    margin-top: 40px;
    &[data-columns="2"] {
      > li {
        @include RESPOND_FROM($BREAKPOINT_768) {
          padding-left: 1.5em;
          &:nth-child(1) {
            margin-top: 0;
            grid-column: 2/8;
          }
          &:nth-child(2) {
            margin-top: 0;
            grid-column: 8/14;
          }
        }
        @include RESPOND_FROM($BREAKPOINT_1024) {
          padding-left: 2em;
          max-width: none;
          &:nth-child(1) {
            grid-column: 2/8;
          }
          &:nth-child(2) {
            grid-column: 8/14;
          }
        }
      }
      .h3 {
        width: 100%;
      }
    }
    > li {
      grid-column: 2/14;
      &:nth-child(2) {
        @include SECTION_CONTENT_SPACE;
      }
      &:nth-child(3) {
        @include SECTION_CONTENT_SPACE;
      }
      @include RESPOND_FROM($BREAKPOINT_400) {
        padding-left: 1em;
      }
      @include RESPOND_FROM($BREAKPOINT_768) {
        padding-left: 1.5em;
        &:nth-child(1) {
          margin-top: 0;
          grid-column: 2/8;
        }
        &:nth-child(2) {
          margin-top: 0;
          grid-column: 8/14;
        }
        &:nth-child(3) {
          grid-column: 2/8;
        }
      }
      @include RESPOND_FROM($BREAKPOINT_1024) {
        padding-left: 2em;
        max-width: 365px;
        &:nth-child(1) {
          grid-column: 2 / span 4;
        }
        &:nth-child(2) {
          grid-column: 6 / span 4;
        }
        &:nth-child(3) {
          margin-top: 0;
          grid-column: 10 / span 4;
        }
      }
      box-sizing: border-box;
      img {
        width: 210px * 0.8;
        height: 195px * 0.8;
        display: block;
        margin: auto;
        @include RESPOND_FROM($BREAKPOINT_768) {
          margin: unset;
        }
        @include RESPOND_FROM($BREAKPOINT_1024) {
          width: 210px;
          height: 195px;
          @include FLUID_PROPERTY(width, 210 * 0.8, 210, $BREAKPOINT_1024);
          @include FLUID_PROPERTY(height, 195 * 0.8, 195, $BREAKPOINT_1024);
        }
      }
      .h3 {
        width: 100%;
        height: 70px;
        overflow: hidden;
        @include SECTION_CONTENT_SPACE;
        @include H3_FONTSTYLE;
        @include RESPOND_UNTIL($BREAKPOINT_768) {
          height: auto;
        }
        &[data-text-align="center"] {
          text-align: center;
          @include RESPOND_FROM($BREAKPOINT_768) {
            text-align: left;
          }
        }
      }
      p {
        @include FLUID_PROPERTY(margin-top, 20, 30);
        @include P_FONTSTYLE;
      }
      .card-items {
        @include FLUID_PROPERTY(margin-top, 20, 30);
        li {
          @include P_FONTSTYLE;
          line-height: 24px;
          margin-top: 1em;
          color: $DEEP_BLUE_80_COLOR;
          strong {
            font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
          }
          &:first-child {
            margin-top: 0;
          }
          position: relative;
          box-sizing: border-box;
          padding-left: 1.3em;
          &:before {
            position: absolute;
            content: " ";
            width: 14px;
            height: 13px;
            left: 0;
            top: 0.3em;
            background-image: url("/assets/icons/list-check2.svg");
            background-repeat: no-repeat;
          }
          &.green {
            &:before {
              width: 22px;
              height: 20px;
              left: -6px;
              top: 0;
              background-image: url("/assets/icons/list-check-softgreen.svg");
            }
          }
        }
        &.bullet {
          li:before {
            position: absolute;
            content: " ";
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: $SOFT_GREEN_100_COLOR;
            top: 8px;
            left: 0;
          }
        }
      }

      a {
        display: block;
        color: #00819c;
        cursor: pointer;
        font-family: "Siemens Sans Black";
        position: relative;
        width: fit-content;
        &:not(.inline) {
          margin-left: 24px;
          margin-top: 40px;
        }
        &:not(.inline):after {
          content: " ";
          width: 22px;
          height: 20px;
          position: absolute;
          right: -40px;
          top: 8px;
          background-image: url("/assets/icons/arrow_right_blue.svg");
          background-repeat: no-repeat;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
