.sgt-howitworks {
  @include PAGE_GRID;
  margin-top: 0;
  @include SECTION_SPACE(padding-top);
  &[data-skip-section-space-at-top-for-mobile="true"] {
    margin-top: 0;
    @include RESPOND_FROM($BREAKPOINT_768) {
      @include SECTION_SPACE;
    }
  }
  &[data-scheme="sand"] {
    @include SECTION_SPACE(padding-top);
    @include SECTION_SPACE(padding-bottom);
    background-color: $SAND_COLOR;
    .sgt-howitworks {
      &-tabs {
        ul {
          background-color: rgba($WHITE_COLOR, 0.6);
        }
      }
      &-keyelement {
        &-tips {
          background-color: $WHITE_COLOR;
        }
        &-beings {
          border-color: $BRIGHT_SAND_COLOR;
        }
        &-block {
          &:nth-child(2n + 2) {
            background-color: $WHITE_COLOR;
          }
        }
      }
    }
  }
  .ext-link {
    grid-column: 9/14;
    margin-bottom: 20px;
    display: flex;
    .animation-container {
      width: 15%;
      margin-right: 20px;
    }
    a {
      color: $LINK_COLOR;
      text-decoration: none;
      display: flex;
      justify-content: flex-end;
      > * {
        font-size: 18px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &:hover {
        color: $HOVER_LINK_COLOR;
        text-decoration: none;
        > :not(.material-icons) {
          font-size: 18px;
          text-decoration: underline;
        }
      }
    }
  }
  .main-tabs {
    grid-column: 2/14;
    display: flex;
    .main-tab {
      border-bottom: 2px solid #ccccd4;
      width: 50%;
      text-align: center;
      cursor: pointer;
      &.active {
        border-bottom: 2px solid #00ffb9;
        font-family: "Siemens Sans Black";
      }
    }
    a {
      color: $DEEP_BLUE_60_COLOR;
      font-weight: normal;
      display: block;
      padding-bottom: 20px;
      &.active {
        font-weight: bold;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  a {
    color: #00819c;
    cursor: pointer;
    font-family: "Siemens Sans Black";
    &:hover {
      text-decoration: underline;
    }
  }
  .h2 {
    grid-column: 2/14;
    @include H2_FONTSTYLE;
    @include RESPOND_FROM($BREAKPOINT_1024) {
      grid-column: 2/10;
    }
  }
  &-desc {
    @include P_FONTSTYLE;
    @include SECTION_SMALL_CONTENT_SPACE;
    grid-column: 2/14;
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 2/11;
    }
    @include RESPOND_FROM($BREAKPOINT_1024) {
      grid-column: 2/10;
    }
    > .sgt-animated-illustration {
      > svg-component {
        visibility: hidden;
        @include RESPOND_FROM($BREAKPOINT_768) {
          visibility: visible;
        }
      }
    }
  }
  &-tabs {
    @include SECTION_LARGE_CONTENT_SPACE;
    grid-column: 1/15;
    @include RESPOND_FROM($BREAKPOINT_1024) {
      grid-column: 2/14;
    }
    > ul {
      position: relative;
      @include RESPOND_FROM($BREAKPOINT_768) {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        background-color: $SAND_COLOR;
        &:after {
          content: " ";
          position: absolute;
          background-color: $DEEP_BLUE_40_COLOR;
          width: 100%;
          bottom: 0;
          height: 1px;
        }
      }
      > li {
        @include RESPOND_FROM($BREAKPOINT_768) {
          flex-basis: 100%;
        }
        border-bottom: 4px solid transparent;
        a:hover {
          text-decoration: none !important;
        }
        &.selected,
        &:hover {
          .sgt-howitworks-tabs-tab-name {
            font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
            text-decoration: none !important;
          }
          &[data-scheme="green"] {
            background-color: rgba($SOFT_GREEN_100_COLOR, 0.1) !important;
            border-bottom-color: $SOFT_GREEN_100_COLOR;
          }
          &[data-scheme="blue"] {
            background-color: rgba($SOFT_BLUE_100_COLOR, 0.1) !important;
            border-bottom-color: $SOFT_BLUE_100_COLOR;
          }
          &[data-scheme="orange"] {
            background-color: rgba($ORANGE_100_COLOR, 0.1) !important;
            border-bottom-color: $ORANGE_100_COLOR;
          }
          &[data-scheme="red"] {
            background-color: rgba($SOFT_RED_100_COLOR, 0.1) !important;
            border-bottom-color: $SOFT_RED_100_COLOR;
          }
        }
        .sgt-howitworks-tabs-tab-name {
          @include FOOTNOTE_FONTSTYLE;
          @include PAGE_MARGIN_IF_NOT_USING_GRID(padding-left, padding-right);
          position: relative;
          cursor: pointer;
          padding-top: 0.8em;
          padding-bottom: 0.8em;
          color: $DEEP_BLUE_100_COLOR;
          font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
          box-sizing: border-box;
          z-index: 2;
          margin-top: 1px;
          background-color: $BRIGHT_SAND_COLOR;
          height: 100%;
          &[data-selected="true"] {
            &:after {
              transform: scaleY(-1);
            }
          }
          @include RESPOND_FROM($BREAKPOINT_768) {
            text-align: center;
            font-weight: $FONT_WEIGHT_NORMAL;
            background-color: transparent;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0.7em;
            padding-bottom: calc(0.7em - 4px);
          }
          @include RESPOND_FROM($BREAKPOINT_1024) {
            @include P_FONTSTYLE;
          }
        }
      }
    }
  }
  &-keyelements {
    grid-column: 1/15;
  }
  &-keyelement {
    @include SECTION_LARGE_CONTENT_SPACE;
    @include PAGE_GRID;
    // &[data-selected="false"] {
    //     display: none;
    // }
    &-tips {
      order: 3;
      grid-column: 2/14;
      box-sizing: border-box;
      padding: 3em 1.5em 3em 3.5em;
      background-color: $SAND_COLOR;
      margin-top: 1em;
      @include RESPOND_FROM($BREAKPOINT_768) {
        margin-top: 0;
        order: 2;
        grid-column: 2/7;
      }
      @include RESPOND_FROM($BREAKPOINT_1024) {
        order: 1;
        grid-column: 2/7;
        grid-row: 1/3;
      }
      h4 {
        @include H5_FONTSTYLE;
        font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
      }
      ul {
        margin-top: 2em;
        li {
          @include FOOTNOTE_FONTSTYLE;
          @include CUSTOM_LIST_ICONS;
          margin-top: 1.5em;
          position: relative;
        }
      }
    }
    &-desc {
      grid-column: 2/14;
      order: 1;
      display: grid;
      grid-template-columns: 120px 1fr;
      grid-gap: 1em;
      @include SECTION_LARGE_CONTENT_SPACE(margin-bottom);
      @include RESPOND_FROM($BREAKPOINT_1024) {
        grid-column: 7/14;
        order: 2;
        margin-bottom: 0;
      }
      gt-animated-illustration {
        grid-column: 1/2;
        transform: scale(1.5);
        visibility: hidden;
        @include RESPOND_FROM($BREAKPOINT_550) {
          visibility: visible;
        }
        .sgt-animated-illustration {
          width: 100%;
          height: 100%;
        }
      }
      &-copy {
        grid-column: 1/3;
        align-self: start;
        @include RESPOND_FROM($BREAKPOINT_550) {
          grid-column: 2/3;
          &.noIllustration {
            grid-column: 1/3;
          }
        }
      }
    }
    &-beings {
      @include P_FONTSTYLE;
      grid-column: 2/14;
      order: 2;
      box-sizing: border-box;
      border: 1px solid $SAND_COLOR;
      padding: 2em 1em;
      align-self: stretch;
      align-content: center;
      display: grid;
      @include RESPOND_FROM($BREAKPOINT_768) {
        padding: 2em;
        grid-column: 7/14;
        order: 3;
      }
      @include RESPOND_FROM($BREAKPOINT_1024) {
        @include P_LARGE_FONTSTYLE;
        margin-top: 2em;
        align-self: end;
      }
      ul {
        background-color: transparent !important;
        li {
          margin-top: 2em;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
    &-blocks {
      grid-column: 1/15;
      order: 4;
      @include RESPOND_FROM($BREAKPOINT_1024) {
        grid-column: 2/14;
      }
    }
    &-block {
      @include PAGE_MARGIN_IF_NOT_USING_GRID(padding-left, padding-right);
      display: grid;
      grid-template-columns: 1fr 100px;
      padding-top: 2em;
      padding-bottom: 2em;
      box-sizing: border-box;
      @include RESPOND_FROM($BREAKPOINT_550) {
        margin-top: 0;
        padding: 3em 2em;
      }
      &:nth-child(2n + 2) {
        background-color: $SAND_COLOR;
      }
      &-content {
        display: grid;
        grid-column: 1/3;
        order: 0;
        column-gap: 1em;
        @include RESPOND_FROM($BREAKPOINT_550) {
          grid-column: 1/2;
        }
        figure {
          grid-row: 1/3;
          grid-column: 3;
        }
      }
      &-title {
        @include H5_FONTSTYLE;
        grid-row: 1;
        grid-column: 1/3;
      }
      &-desc {
        @include SECTION_CONTENT_SPACE;
        @include P_FONTSTYLE;
        grid-row: 2;
        grid-column: 1/3;
        order: 1;
        @include RESPOND_FROM($BREAKPOINT_550) {
          order: 2;
        }
      }
      &-meta {
        position: relative;
        grid-column: 1/3;
        order: 2;
        margin-top: 1em;
        left: -10px;
        @include RESPOND_FROM($BREAKPOINT_550) {
          order: 1;
          margin-top: 0;
          justify-self: end;
          grid-column: 2/3;
          left: 10px;
        }
        &-download {
          display: inline-block;
          font-size: 25px;
          cursor: pointer;
          color: $DEEP_BLUE_100_COLOR;
          &:hover,
          &:active {
            color: $SOFT_BLUE_100_COLOR;
          }
        }
      }
      &-facts {
        @include SECTION_CONTENT_SPACE;
        order: 3;
        grid-column: 1/3;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 2em;
        @include RESPOND_FROM($BREAKPOINT_550) {
          grid-template-columns: 1fr 1fr;
        }
        &-fact {
          @include RESPOND_FROM($BREAKPOINT_550) {
            &.full-width {
              grid-column: span 2;
            }
            &.half-width {
              grid-column: span 1;
            }
          }
          h5 {
            @include H7_FONTSTYLE;
            background-color: rgba($SOFT_GREEN_100_COLOR, 0.12);
            box-sizing: border-box;
            padding: 0.5em 1em;
            color: $DEEP_BLUE_80_COLOR;
            &.blue {
              background-color: rgba($SOFT_BLUE_100_COLOR, 0.12);
            }
            &.orange {
              background-color: rgba($ORANGE_100_COLOR, 0.12);
            }
            &.red {
              background-color: rgba($SOFT_RED_100_COLOR, 0.12);
            }
          }
          ul {
            box-sizing: border-box;
            padding: 1em 1em 1em 3em;
            background-color: transparent !important;
            li {
              @include P_FONTSTYLE;
              @include CUSTOM_LIST_ICONS;
              margin-top: 1em;
              position: relative;
              &:first-child {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
    .sgt-illustratedteaser {
      order: 4;
      grid-column: 1/15;
      @include SECTION_SPACE(padding-bottom);
      @include RESPOND_FROM($BREAKPOINT_768) {
        padding-bottom: 0;
      }
    }
  }
}
