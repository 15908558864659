.sgt-genericteaser {
  @include SECTION_SPACE(padding-top);
  @include SECTION_SPACE(padding-bottom);
  @include PAGE_GRID;
  align-items: center;
  &-content {
    grid-column: 2/ 14;
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 2/ 12;
    }
    @include RESPOND_FROM($BREAKPOINT_1024) {
      grid-column: 2/ 7;
      max-width: 400px;
    }
    &-cta {
      .h2 {
        @include H2_FONTSTYLE;
        max-width: 350px;
        @include RESPOND_FROM($BREAKPOINT_1024) {
          @include H1_FONTSTYLE;
          max-width: unset;
        }
      }
      p {
        @include SECTION_SMALL_CONTENT_SPACE;
        @include P_FONTSTYLE;
      }
      a {
        @include SECTION_CONTENT_SPACE;
        display: block;
      }
    }
    &-quote {
      @include FLUID_PROPERTY(margin-top, 32, 40, $BREAKPOINT_400, $BREAKPOINT_768);
      @include RESPOND_FROM($BREAKPOINT_768) {
        margin-top: 0;
      }
      blockquote {
        @include QUOTE_FONTSTYLE("simple");
        max-width: 550px;
      }
      p {
        @include SECTION_SMALL_CONTENT_SPACE;
        @include QUOTE_AUTHOR_FONTSTYLE;
      }
    }
  }
  figure {
    grid-column: 1/15;
    @include SECTION_SPACE();
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 4/15;
    }
    @include RESPOND_FROM($BREAKPOINT_1024) {
      margin-top: 0;
      grid-column: 7/15;
    }
    img {
      width: 100%;
      object-fit: cover;
      min-height: 400px;
    }
    svg-component {
      transform: scale(1.2);
      @include RESPOND_FROM($BREAKPOINT_550) {
        transform: unset;
      }
    }
  }
}
