.btn,
button {
  //height: 30px;
  padding: 10px 30px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Siemens Sans Black";
  border: none;
  display: flex;
  cursor: pointer;
  width: fit-content;

  &.size-restrict {
    max-width: 260px;
    height: 60px;
  }

  > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .rearIcon {
    margin-left: 15px;
    font-size: 20px;
  }

  .frontIcon {
    margin-right: 15px;
    font-size: 20px;
  }
}

button.icon {
  padding: 0;
  font-size: 24px;
  background-color: transparent;
}

.btn-hero {
  background: $DYNAMIC_PETROL_GRADIENT;
  color: $DEEP_BLUE_100_COLOR;
  width: fit-content;

  > span {
    text-align: center;
    width: 100%;
    align-self: center;
  }

  &:hover {
    background: $LIGHT_GREEN_COLOR;
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
  }
}

.btn-primary {
  background-color: #007993;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #196269;
  }

  &.disabled,
  &:disabled {
    background-color: #b3b3be;
    pointer-events: none;
  }
}

.btn-danger {
  background-color: #c6383f;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Siemens Sans Black";

  &:hover {
    background-color: #93292e;
  }
}

.btn-secondary {
  background-color: transparent;
  color: #007993;
  border: 1px solid #007993;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &.active {
    background-color: #c2ffee;
    color: #005159;
    border-color: #005159;
  }
}

.btn-tetriary {
  background-color: transparent;
  color: #007993;

  &:hover {
    background-color: #c2ffee;
    color: #005159;
  }
}

.btn-inline {
  border: none;
  background: none;
  &.text-align-start {
    text-align: start;
  }
  &.link-style {
    padding: 0;
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: $LINK_COLOR;
  }
}
