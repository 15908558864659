.sgt-faq {
  width: 80%;
  margin-right: auto;
  margin-left: auto;

  @include SECTION_SPACE(margin-top);
  @include SECTION_SPACE(margin-bottom);
  > .h2 {
    @include H2_FONTSTYLE;
    margin: 20px 0;
  }
  &-selector {
    @include SECTION_CONTENT_SPACE;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    p {
      @include P_FONTSTYLE;
      @include RESPOND_FROM($BREAKPOINT_1024) {
        text-align: center;
      }
    }

    .sgt-faq-selector-item {
      cursor: pointer;
      user-select: none;
      -webkit-touch-callout: none;
      align-items: center;
      justify-items: center;
      box-sizing: border-box;
      padding: 0.5em;
      width: 192px;
      height: 135px;
      position: relative;
      background: $SAND_COLOR;
      border-radius: 12px;
      margin: 0.5rem;
      border: 1px solid transparent;
      @include RESPOND_FROM($BREAKPOINT_1440) {
        width: 192px;
      }
      &[data-selected="true"] {
        background-color: $SOFT_GREEN_12_COLOR;
        border: 1px solid $SOFT_GREEN_100_COLOR;
      }
      &:hover,
      &:active {
        background-color: $SOFT_GREEN_12_COLOR;
        border: 1px solid $SIEMENS_PETROL_100_COLOR;
      }
      .img-container {
        margin: auto;
        width: fit-content;
      }
      img {
        pointer-events: none;
        width: 45px;
        height: 45px;
        object-fit: contain;
      }
      .h3 {
        @include FOOTNOTE_FONTSTYLE;
        text-align: center;
        line-height: 20px;
      }
    }
  }
  .input-container {
    @include SECTION_SPACE;
    @include SECTION_SPACE(margin-bottom);
    position: relative;
  }
  .input {
    border: 1px solid $DEEP_BLUE_60_COLOR !important;
    border-radius: 90px;
    padding: 17px 30px;
    line-height: 28px;
    font-size: 18px;
    width: 100%;
  }
  .input-btn {
    width: 56px;
    height: 56px;
    box-sizing: border-box;
    position: absolute;
    cursor: pointer;
    top: 4px;
    right: -2%;
    width: 56px;
    height: 56px;
    box-sizing: border-box;
    position: absolute;
    cursor: pointer;
    background-color: transparent;
    &:before {
      position: absolute;
      content: " ";
      background-image: url("/assets/icons/search.svg");
      background-position: center;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &-questions {
    @include SECTION_CONTENT_SPACE;
    grid-column: 2/14;
    border-bottom: 1px solid $DEEP_BLUE_100_COLOR;
    @include RESPOND_FROM($BREAKPOINT_1024) {
      grid-column: 3/13;
    }
    &-entry {
      @include SECTION_CONTENT_SPACE(padding-top);
      @include SECTION_CONTENT_SPACE(padding-bottom);
      position: relative;
      box-sizing: border-box;
      border-top: 1px solid $DEEP_BLUE_100_COLOR;
      @include RESPOND_FROM($BREAKPOINT_1024) {
        padding-left: 1em;
        padding-right: 0;
      }
      .h3 {
        @include H6_FONTSTYLE;
        padding-right: 50px;
        @include RESPOND_FROM($BREAKPOINT_1024) {
          padding-right: 0;
        }
      }
      .sgt-faq-questions-entry-answer {
        overflow: hidden;
        p {
          @include SECTION_CONTENT_SPACE;
          @include P_FONTSTYLE;
          @include FLUID_PROPERTY(padding-bottom, 20, 30);
          overflow: hidden;
        }
      }
      .sgt-faq-question-stateindicator {
        position: absolute;
        right: 1em;
        cursor: pointer;
        width: 100%;
        @include FLUID_PROPERTY(height, 85, 110);
        top: 0;
        left: 0;
        &-collapse {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
        &-uncollapse {
          position: absolute;
          display: none;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
    }
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    background: none;
  }
}
