.carousel-container {
  background-color: white;

  .carousel {
    @include PAGE_GRID;
    position: relative;
  }

  .slide {
    display: none;
    grid-column: 2/14;

    .photo-container {
      width: 50%;
      align-self: center;
      @include FLUID_PROPERTY(height, 200, 500, $BREAKPOINT_320, $BREAKPOINT_1024);
      @include RESPOND_FROM($BREAKPOINT_1024) {
        grid-column: 1 / 8;
        @include FLUID_PROPERTY(height, 500, 700, $BREAKPOINT_1024);
      }
    }

    .content {
      width: 50%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include FLUID_PROPERTY_ADVANCED(padding-top, 80, 80, 80, 130);
      @include FLUID_PROPERTY(padding-left, 20, 30);
      @include FLUID_PROPERTY(padding-right, 20, 30);
      @include FLUID_PROPERTY(padding-bottom, 20, 30);
      blockquote {
        @include QUOTE_FONTSTYLE;
        color: $WHITE_COLOR;
        margin-top: 40px;
      }
      p {
        @include SECTION_SMALL_CONTENT_SPACE;
        @include QUOTE_AUTHOR_FONTSTYLE;
      }
      .highlight {
        color: $PURPLE;
      }

      .illustration-container {
        height: 20%;
        flex-shrink: 1;

        img {
          height: 100%;
          float: right;
        }
      }
    }

    &.sand {
      background-color: $SAND_COLOR;

      .content blockquote {
        color: $DEEP_BLUE_100_COLOR;
      }
    }

    &.dark {
      background-color: $DEEP_BLUE_100_COLOR;
    }

    &.light {
      background-color: $WHITE_COLOR;

      .content blockquote {
        color: $DEEP_BLUE_100_COLOR;
      }
    }

    &.active {
      display: flex;
      animation-name: slideFromRight;
      animation-duration: 1s;
    }

    &.slideFromLeft {
      animation-name: slideFromLeft;
      animation-duration: 1s;
    }

    @keyframes slideFromRight {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(0%);
      }
    }

    @keyframes slideFromLeft {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(0%);
      }
    }
  }

  button {
    border-radius: 100%;
    width: 56px;
    height: 56px;
    font-size: 35px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    position: absolute;
    top: 50%;
    z-index: 1;
  }

  button.previous {
    left: 5%;
  }

  button.next {
    right: 5%;
  }

  @include RESPOND_UNTIL($BREAKPOINT_1440) {
    button.previous {
      left: 0%;
    }

    button.next {
      right: 0%;
    }
  }

  @include RESPOND_UNTIL($BREAKPOINT_1024) {
    button.previous {
      top: unset;
      bottom: 10px;
      left: calc(50% - 60px);
    }

    button.next {
      top: unset;
      bottom: 10px;
      right: calc(50% - 60px);
    }
  }
}
