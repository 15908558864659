.lessonsLearned-section {
  width: 100%;
  background-color: $DEEP_BLUE_100_COLOR;
  padding: 60px 0;
  .text-container {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 0 repeat(12, 1fr) 0;
    @include RESPOND_FROM($BREAKPOINT_400) {
      grid-template-columns: 5px repeat(17, 1fr) 5px;
    }
    @include RESPOND_FROM($BREAKPOINT_1024) {
      grid-template-columns: 30px repeat(17, 1fr) 30px;
    }
    @include RESPOND_FROM($BREAKPOINT_1440) {
      grid-template-columns: 1fr repeat(17, 65px) 1fr;
    }
    .h1 {
      @include H1_FONTSTYLE;
      grid-column: 2/19;
      color: $WHITE_COLOR;
    }
    p {
      @include P_FONTSTYLE;
      color: $WHITE_COLOR;
      grid-column: 2/19;
    }
  }

  .calendar {
    margin-top: 40px;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 0 repeat(12, 1fr) 0;
    grid-template-rows: repeat(6, 60px);
    @include RESPOND_FROM($BREAKPOINT_400) {
      grid-template-columns: 5px repeat(17, 1fr) 5px;
      grid-gap: 5px;
    }
    @include RESPOND_FROM($BREAKPOINT_1024) {
      grid-template-columns: 30px repeat(17, 1fr) 30px;
      grid-gap: 5px;
    }
    @include RESPOND_FROM($BREAKPOINT_1440) {
      grid-template-columns: 1fr repeat(17, 65px) 1fr;
      grid-gap: 15px;
    }
    .grid__item {
      //border: 1px solid white;
      background-color: $DEEP_BLUE_100_COLOR;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      background-position: center;
      &:hover {
        transform: scale(1.8);
        border-radius: 20px;
        z-index: 9;
      }
    }
  }
}
.calendarDialog {
  .mdc-dialog__container {
    background-color: $DEEP_BLUE_100_COLOR;
    color: white;
    position: relative;
    max-width: 1370px;
    margin: auto;
  }
  .mat-dialog-title {
    display: flex;
    justify-content: flex-end;
    .material-icons {
      cursor: pointer;
    }
  }
  .bg {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.calendar .grid__item {
  &--1 {
    background-image: url("/assets/images/lessons_learned/1.svg");
  }
  &--2 {
    background-image: url("/assets/images/lessons_learned/2.svg");
  }
  &--3 {
    background-image: url("/assets/images/lessons_learned/3.svg");
  }
  &--4 {
    background-image: url("/assets/images/lessons_learned/4.svg");
  }
  &--5 {
    background-image: url("/assets/images/lessons_learned/5.svg");
  }
  &--6 {
    background-image: url("/assets/images/lessons_learned/6.svg");
  }
  &--7 {
    background-image: url("/assets/images/lessons_learned/7.svg");
  }
  &--8 {
    background-image: url("/assets/images/lessons_learned/8.svg");
  }
  &--9 {
    background-image: url("/assets/images/lessons_learned/9.svg");
  }
  &--10 {
    background-image: url("/assets/images/lessons_learned/10.svg");
  }
  &--11 {
    background-image: url("/assets/images/lessons_learned/11.svg");
  }
  &--12 {
    background-image: url("/assets/images/lessons_learned/12.svg");
  }
}
.calendarDialog {
  .bg {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &--blue {
      background-image: url("/assets/images/calendar/bg-blue.svg");
    }
    &--green {
      background-image: url("/assets/images/calendar/bg-green.svg");
    }
    &--orange {
      background-image: url("/assets/images/calendar/bg-orange.svg");
    }
    &--pink {
      background-image: url("/assets/images/calendar/bg-pink.svg");
    }
    &--purple {
      background-image: url("/assets/images/calendar/bg-purple.svg");
    }
    &--yellow {
      background-image: url("/assets/images/calendar/bg-yellow.svg");
    }
  }
  .close-btn {
    position: absolute;
    width: 45px;
    height: 40px;
    right: 3rem;
    top: 3rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    &--blue {
      background-image: url("/assets/images/calendar/close-blue.svg");
    }
    &--green {
      background-image: url("/assets/images/calendar/close-green.svg");
    }
    &--orange {
      background-image: url("/assets/images/calendar/close-orange.svg");
    }
    &--pink {
      background-image: url("/assets/images/calendar/close-pink.svg");
    }
    &--purple {
      background-image: url("/assets/images/calendar/close-purple.svg");
    }
    &--yellow {
      background-image: url("/assets/images/calendar/close-yellow.svg");
    }
  }
  .content-container.lessonsLearned {
    height: 100%;
    display: grid;
    grid-template-columns: 0 repeat(12, 1fr) 0;
    @include RESPOND_FROM($BREAKPOINT_400) {
      grid-template-columns: 30px repeat(12, 1fr) 15px;
    }
    @include RESPOND_FROM($BREAKPOINT_1024) {
      grid-template-columns: 60px repeat(12, 1fr) 30px;
    }
    @include RESPOND_FROM($BREAKPOINT_1440) {
      grid-template-columns: 150px repeat(12, 1fr) 150px;
    }
    .content {
      grid-column: 2/14;
      padding: 10% 0;
      .number {
        font-size: 72px;
        line-height: 72px;
      }
      .title {
        font-size: 2rem;
        margin-bottom: 40px;
        margin-top: 20px;
      }
      .text {
        font-size: 1.5rem;
        a {
          outline: none !important;
          &:hover {
            cursor: pointer !important;
          }
        }
        &.pink {
          a {
            color: #ee898c;
          }
        }
        &.yellow {
          a {
            color: #ffd732;
          }
        }
        &.purple {
          a {
            color: #aa32be;
          }
        }
        &.blue {
          a {
            color: #00bedc;
          }
        }
        &.green {
          a {
            color: #00ffb9;
          }
        }
        &.orange {
          a {
            color: #ff9000;
          }
        }
      }
    }
  }
}
