.wizard-sidenav {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 100px;

  .img-container {
    position: relative;
    width: 24px;
    height: 24px;
    background-color: $SAND_COLOR;
    padding: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-bottom: 5px;
    padding: 5px;
    &.active {
      background-color: $WHITE_COLOR;
    }
  }

  .nav-badge-container {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -10px;
    right: -10px;
  }

  .sidenav-item {
    font-size: 14px;
    padding: 16px 10px 16px 16px;
    line-height: 20px;
    cursor: pointer;
    width: 100%;

    img {
      width: 100%;
      max-height: 100%;
    }

    &.active {
      background-color: $SAND_COLOR;
      pointer-events: none;
    }

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &.clickable:not(.active) {
      cursor: pointer;
    }
  }

  .sub-items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    padding-left: 10px;
  }

  .sidenav-subitem {
    display: block;
    line-height: 16px;

    &.active {
      color: $LINK_COLOR;
      font-family: "Siemens Sans Black";
      border-right: 2px solid $LINK_COLOR;
    }
  }
}
