.mdc-dialog__container {
  .header {
    display: flex;
    justify-content: space-between;
  }

  .btns-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    > *:not(:last-child) {
      margin-right: 30px;
    }
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover {
    background: transparent !important;
  }
}

.mdc-dialog,
.mdc-dialog__scrim,
.mat-mdc-dialog-container {
  max-height: 100vh !important;
}

.mdc-dialog__surface {
  padding: 20px;
}

.strengthscope-instructions {
  margin-block: 20px;

  a {
    color: $BLUE_COLOR;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  li {
    margin-block: 20px;
  }
}
