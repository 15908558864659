.sgt-subpagehero {
  @include PAGE_GRID;
  @include SECTION_CONTENT_SPACE(padding-top);
  position: relative;
  &[data-scheme="dark"] {
    background-color: $DEEP_BLUE_100_COLOR;
    .sgt-subpagehero-copy {
      .h2 > *,
      .h3 {
        color: $WHITE_COLOR;
      }
      .h2 > span {
        white-space: pre;
      }
      p {
        color: $WHITE_COLOR;
      }
    }
  }
  &[data-scheme="sand"] {
    background-color: $SAND_COLOR;
    .sgt-subpagehero-copy {
      .h2 > * {
        color: $DEEP_BLUE_100_COLOR;
      }
      .h2 > span {
        white-space: pre;
      }
      p {
        color: $DEEP_BLUE_100_COLOR;
      }
    }
  }
  &[data-illustration-mobile-position="right-to-title"] {
    @include RESPOND_UNTIL($BREAKPOINT_550) {
      figure,
      .animation {
        grid-column: 10 / 13;
        order: 2;
        position: absolute;
        top: 3%;
        left: 0;
        @include FLUID_PROPERTY(width, 400, 450, $BREAKPOINT_320, $BREAKPOINT_550);
      }
      .sgt-subpagehero-copy {
        @include SECTION_SPACE(margin-bottom);
        h1,
        .h2 > * {
          max-width: 70%;
        }
      }
    }
  }
  &[data-illustration-mobile-position="below-desc"] {
    @include RESPOND_UNTIL($BREAKPOINT_550) {
      figure {
        grid-column: 2 / 14;
        @include SECTION_LARGE_CONTENT_SPACE;
      }
    }
  }
  &[data-image-position-when-next-to-copy="right"] {
    @include RESPOND_FROM($BREAKPOINT_768) {
      .sgt-subpagehero-copy {
        @include SECTION_SPACE(padding-bottom);
        margin-bottom: 0 !important;
        grid-column: 2/9;
        padding-right: 3em;
        box-sizing: border-box;
      }
      figure {
        grid-column: 9/15 !important;
      }
    }
  }
  &[data-image-position-when-next-to-copy="left"] {
    @include RESPOND_FROM($BREAKPOINT_768) {
      figure {
        order: 1;
        align-self: end;
        &[data-size="normal"] {
          grid-column: 1/9;
        }
        &[data-size="reducedForPageNavigation"] {
          grid-column: 1/9;
        }
      }
      .sgt-subpagehero-copy {
        @include SECTION_SPACE(margin-bottom);
        grid-column: 9/14;
        order: 2;
      }
    }
    @include RESPOND_FROM($BREAKPOINT_1440) {
      figure {
        &[data-size="reducedForPageNavigation"] {
          grid-column: 2/9;
        }
      }
    }
  }
  &[data-image-position-when-below-copy="left"] {
    @include RESPOND_FROM($BREAKPOINT_550) {
      figure {
        position: relative;
        grid-column: 1/12;
        width: 100%;
        top: unset;
        right: unset;
      }
      .sgt-subpagehero-copy {
        @include SECTION_SPACE(margin-bottom);
      }
    }
  }
  &[data-image-position-when-below-copy="right"] {
    @include RESPOND_FROM($BREAKPOINT_550) {
      figure {
        position: relative;
        grid-column: 4/15;
        width: 100%;
        top: unset;
        right: unset;
      }
      .sgt-subpagehero-copy {
        @include SECTION_SPACE(margin-bottom);
      }
    }
  }
  .sgt-subpagehero-copy {
    @include SECTION_SMALL_CONTENT_SPACE;
    position: relative;
    z-index: 2;
    grid-column: 2/14;
    box-sizing: border-box;
    .h3 {
      @include SUBTITLE_FONTSTYLE($DARK_GREEN_COLOR);
    }
    .h2 > * {
      @include H2_FONTSTYLE;
    }
    p {
      @include SECTION_CONTENT_SPACE;
      @include P_FONTSTYLE;
      max-width: 650px;
    }
  }
  figure {
    position: relative;
    z-index: 1;
    line-height: 0;
    align-self: end;
    @include RESPOND_UNTIL($BREAKPOINT_550) {
      max-width: 350px;
      svg-component {
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
      }
    }
    img {
      width: 100%;
      visibility: hidden;
      @include RESPOND_FROM($BREAKPOINT_550) {
        visibility: visible;
      }
    }
  }
}
