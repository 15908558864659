$WHITE_COLOR: #ffffff;
$SAND_COLOR: #f3f3f0;
$SAND_COLOR_HOVER: #e8e8e3;
$DISABLED_COLOR: #b3b3be;
$BRIGHT_SAND_COLOR: #dfdfd9;
$DEEP_BLUE_100_COLOR: #000028;
$DEEP_BLUE_80_COLOR: #333353;
$DEEP_BLUE_60_COLOR: #66667e;
$DEEP_BLUE_40_COLOR: #9999a9;
$DEEP_BLUE_20_COLOR: #ccccd4;
$BLUE_COLOR: #0087be;
$SOFT_BLUE_100_COLOR: #00bedc;
$BOLD_BLUE_COLOR: #00e6dc;
$LIGHT_GREEN_COLOR: #c1feec;
$DARK_GREEN_COLOR: #00646e;
$BOLD_GREEN_COLOR: #00ffb9;
$SOFT_GREEN_100_COLOR: #00d7a0;
$SOFT_GREEN_12_COLOR: #dafbf4;
$SIEMENS_PETROL_100_COLOR: #009999;
$SOFT_RED_100_COLOR: #fe8389;
$ORANGE_100_COLOR: #ff9000;
$GREY: #e5e5e9;
$LINK_COLOR: #007993;
$HOVER_LINK_COLOR: #005159;
$DARK_LINK: #00cccc;
$DARK_LINK_HOVER: #00ffb9;
$VIOLET: #aa32be;
$PURPLE: #7b57ff;
$DYNAMIC_PETROL_GRADIENT: linear-gradient(180deg, $BOLD_GREEN_COLOR 0%, $BOLD_BLUE_COLOR 100%);
$DARK_BG_SECONDARY: #23233c;
$FEEDBACK_GREEN: #018136;
$FEEDBACK_RED: #d72339;
