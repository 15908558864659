.whats-next {
  @include SECTION_SPACE(padding-top);
  @include SECTION_SPACE(padding-bottom);

  &[data-scheme="sand"] {
    background-color: $SAND_COLOR;
  }

  .content {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .h2 {
    margin-top: 8px;
    margin-bottom: 40px;
  }

  .h3 {
    margin-top: 8px;
    margin-bottom: 40px;
  }

  .btn {
    min-width: 124px;
  }

  .subtitle {
    @include SUBTITLE_FONTSTYLE($DEEP_BLUE_100_COLOR);
    @include SECTION_CONTENT_SPACE;
  }
}
