/* ----------------------------------------------------------------
 * FONTS
 * --------------------------------------------------------------*/

$FONT_FAMILY_SIEMENS_SANS: "Siemens Sans";
$FONT_FAMILY_SIEMENS_SANS_BLACK: "Siemens Sans Black";
$FONT_WEIGHT_NORMAL: 400;
$FONT_WEIGHT_BOLD: 900;

@font-face {
  font-family: $FONT_FAMILY_SIEMENS_SANS;
  font-weight: $FONT_WEIGHT_NORMAL;
  src: url("/assets/fonts/SiemensSans_Prof_Roman.woff2") format("woff2");
}

@font-face {
  font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
  font-weight: $FONT_WEIGHT_BOLD;
  src: url("/assets/fonts/SiemensSans_Prof_Black.woff2") format("woff2");
}

//material-icons
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/MaterialIconsOutlined-Regular.otf");
}

.material-icons,
.material-icons-outlined {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

/* ----------------------------------------------------------------
 * SPECIAL FONT STYLES
 * --------------------------------------------------------------*/

@mixin MENUBAR_ITEM_FONTSTYLE($isFlyout: false, $isCurrent: false) {
  font-family: $FONT_FAMILY_SIEMENS_SANS;
  font-weight: $FONT_WEIGHT_NORMAL;
  @if $isCurrent {
    font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
  } @else {
    font-weight: $FONT_WEIGHT_NORMAL;
  }
  @if $isFlyout {
    font-size: 18px;
    line-height: 28px;
  } @else {
    font-size: 16px;
    line-height: 18px;
  }
}

@mixin MENUBAR_TITLE_FONTSTYLE() {
  font-family: $FONT_FAMILY_SIEMENS_SANS;
  letter-spacing: -0.01em;
  font-size: 18px;
  line-height: 18px;
  // @include FLUID_PROPERTY(font-size, 18, 28, $BREAKPOINT_768, $BREAKPOINT_1440);
  // @include FLUID_PROPERTY(line-height, 18, 28, $BREAKPOINT_768, $BREAKPOINT_1440);
}

@mixin QUOTE_FONTSTYLE($layout: "simple") {
  font-family: $FONT_FAMILY_SIEMENS_SANS;
  font-weight: $FONT_WEIGHT_NORMAL;
  @include FLUID_PROPERTY(font-size, 18, 28);
  @include FLUID_PROPERTY(line-height, 22, 38);
  strong {
    font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
  }
  margin: 0;
  position: relative;
  &:before {
    background-image: url("/assets/icons/quote-softblue.svg");
  }
  &.purple {
    &:before {
      background-image: url("/assets/icons/quote-purple.svg");
    }
  }
  &:before {
    position: absolute;
    content: " ";
    background-size: 100%;
    top: -1.5em;
    background-image: url("/assets/icons/quote-softgreen.svg");
    @include FLUID_PROPERTY(width, 28, 42);
    @include FLUID_PROPERTY(height, 17, 30);
  }
}

@mixin QUOTE_AUTHOR_FONTSTYLE() {
  font-family: $FONT_FAMILY_SIEMENS_SANS;
  font-weight: $FONT_WEIGHT_NORMAL;
  @include FLUID_PROPERTY(font-size, 18, 22);
  @include FLUID_PROPERTY(line-height, 28, 32);
  color: $DEEP_BLUE_60_COLOR;
}

/* ----------------------------------------------------------------
 * GENERIC FONT STYLES
 * --------------------------------------------------------------*/

@mixin H1_FONTSTYLE($color: $DEEP_BLUE_100_COLOR) {
  font-family: $FONT_FAMILY_SIEMENS_SANS;
  font-weight: $FONT_WEIGHT_NORMAL;
  @include FLUID_PROPERTY(font-size, 40, 60);
  @include FLUID_PROPERTY(line-height, 48, 72);
  letter-spacing: -0.5px;
  color: $color;
  strong {
    font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
  }
}

@mixin H2_FONTSTYLE($color: $DEEP_BLUE_100_COLOR) {
  font-family: $FONT_FAMILY_SIEMENS_SANS;
  font-weight: $FONT_WEIGHT_NORMAL;
  @include FLUID_PROPERTY(font-size, 36, 40);
  @include FLUID_PROPERTY(line-height, 46, 52);
  letter-spacing: -0.5px;
  color: $color;
  strong {
    font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
  }
}

@mixin H3_FONTSTYLE($theBiggerOne: false, $color: $DEEP_BLUE_100_COLOR) {
  font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
  @if $theBiggerOne {
    @include FLUID_PROPERTY(font-size, 24, 36);
    @include FLUID_PROPERTY(line-height, 32, 46);
  } @else {
    @include FLUID_PROPERTY(font-size, 20, 25);
    @include FLUID_PROPERTY(line-height, 28, 34);
  }
  color: $color;
}

@mixin H4_FONTSTYLE($color: $DEEP_BLUE_100_COLOR) {
  font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@mixin H5_FONTSTYLE($color: $DEEP_BLUE_100_COLOR) {
  font-family: $FONT_FAMILY_SIEMENS_SANS;
  font-weight: $FONT_WEIGHT_NORMAL;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  strong {
    font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
  }
}

@mixin H6_FONTSTYLE($color: $DEEP_BLUE_100_COLOR) {
  font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
  font-size: 20px;
  line-height: 28px;
  color: $color;
}

@mixin H7_FONTSTYLE($color: $DEEP_BLUE_100_COLOR) {
  font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@mixin P_LARGE_FONTSTYLE() {
  font-family: $FONT_FAMILY_SIEMENS_SANS;
  font-weight: $FONT_WEIGHT_NORMAL;
  font-size: 22px;
  line-height: 32px;
  color: $DEEP_BLUE_80_COLOR;
  strong {
    font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
  }
}

@mixin P_FONTSTYLE($color: $DEEP_BLUE_100_COLOR) {
  font-family: $FONT_FAMILY_SIEMENS_SANS;
  font-weight: $FONT_WEIGHT_NORMAL;
  font-size: 18px;
  line-height: 28px;
  color: $color;
  strong {
    font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
  }
}

@mixin FOOTNOTE_FONTSTYLE() {
  font-family: $FONT_FAMILY_SIEMENS_SANS;
  font-weight: $FONT_WEIGHT_NORMAL;
  font-size: 16px;
  line-height: 26px;
  color: $DEEP_BLUE_100_COLOR;
  strong {
    font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
  }
}

@mixin SUBTITLE_FONTSTYLE($color: $DARK_GREEN_COLOR) {
  font-family: $FONT_FAMILY_SIEMENS_SANS_BLACK;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $color;
}
