.sgt-resources {
  @include PAGE_GRID;
  p {
    @include P_FONTSTYLE;
    grid-column: 2/14;
  }
  .sgt-resources-filters-container {
    @include PAGE_GRID;
    @include SECTION_CONTENT_SPACE(padding-top);
    grid-column: 1/15;
    .h2 {
      @include H2_FONTSTYLE;
      grid-column: 2/14;
    }
    p {
      @include P_FONTSTYLE;
      grid-column: 2/14;
    }
    .btn-container {
      grid-column: 2/14;
      > button {
        margin-left: auto;
      }
    }
    .filering-methods-list {
      grid-column: 2/14;
      display: flex;
      gap: 30px;
      > * {
        flex-grow: 1;
      }
    }
    .method {
      @include SECTION_SMALL_CONTENT_SPACE(padding-top);
      grid-column: 2/14;
      .filters {
        display: flex;
        gap: 30px;
        > * {
          flex-grow: 1;
        }
      }
      ul {
        @include SECTION_SMALL_CONTENT_SPACE(padding-top);
        @include SECTION_LARGE_CONTENT_SPACE(padding-bottom);
        box-sizing: border-box;
        width: 100%;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        li {
          flex-shrink: 0;
          display: inline-block;
          a {
            display: inline-block;
            @include FOOTNOTE_FONTSTYLE;
            text-decoration: none !important;
            border-radius: 5em;
            padding: 0.3em 1.3em 0.3em 1.3em;
            color: $BLUE_COLOR;
            border: 1px solid $BLUE_COLOR;
            background-color: transparent;
            display: flex;
            &:hover,
            &:active {
              background-color: $LIGHT_GREEN_COLOR;
            }

            > * {
              display: flex;
              flex-direction: column;
              justify-content: center;
              &:last-child {
                margin-left: 10px;
              }
            }
            &.selected {
              padding: 0.3em 0.7em 0.3em 1.3em;
              color: $DARK_GREEN_COLOR;
              border: 1px solid $DARK_GREEN_COLOR;
              background-color: $LIGHT_GREEN_COLOR;
              &:hover,
              &:active {
                background-color: $BOLD_BLUE_COLOR;
              }
            }
          }
        }
      }
    }
  }
  .sgt-illustratedteaser {
    grid-column: 1/15;
  }
  &-categories {
    grid-column: 1/15;
  }
  &-category {
    @include PAGE_GRID;
    overflow: hidden;
    height: auto;
    .h2 {
      @include FLUID_PROPERTY(padding-top, 80, 100, $BREAKPOINT_320, $BREAKPOINT_550);
      @include H2_FONTSTYLE;
      grid-column: 2/14;
      @include RESPOND_FROM($BREAKPOINT_550) {
        @include FLUID_PROPERTY(padding-top, 30, 60);
      }
    }
    > div:not(.h2) {
      @include PAGE_GRID;
      grid-column: 2/14;
      p.noItems {
        grid-column: 2/14;
      }
    }
    &-collections {
      @include PAGE_GRID;
      @include SECTION_LARGE_CONTENT_SPACE(padding-bottom);
      grid-column: 1/15;
    }
    &-collection {
      @include PAGE_GRID;
      @include SECTION_CONTENT_SPACE;
      @include SECTION_LARGE_CONTENT_SPACE(margin-bottom);
      grid-column: 1/15;
      &-items {
        @include PAGE_GRID;
        @include FLUID_PROPERTY(margin-top, 20, 30);
        @include PAGE_GRID_GAP(grid-row-gap);
        grid-column: 1/15;
      }
      &-item {
        filter: drop-shadow(0px 0px 30px rgba(102, 102, 126, 0.15));
        background-color: $WHITE_COLOR;
        grid-column: 2/14;
        @include RESPOND_FROM($BREAKPOINT_550) {
          @include USE_2_COLUMNS;
        }
        @include RESPOND_FROM($BREAKPOINT_1024) {
          @include USE_3_COLUMNS;
        }
        > figure {
          @include FLUID_PROPERTY(height, 192, 235);
          position: relative;
          .sgt-resources-category-collection-item-flag {
            position: absolute;
            user-select: none;
            pointer-events: none;
            text-transform: capitalize;
            z-index: 1;
            top: 1em;
            left: 1em;
            padding: 0.3em 1.5em;
            border-radius: 50px;
            @include FOOTNOTE_FONTSTYLE;
            color: $WHITE_COLOR;
          }
          .sgt-resources-category-collection-item-updated {
            background-color: $SOFT_BLUE_100_COLOR;
          }
          img {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        > div {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          margin: 2em 1.2em 2em;
          position: relative;
          @include RESPOND_FROM($BREAKPOINT_768) {
            margin: 2em 1.5em 2em;
          }
          h4 {
            @include H6_FONTSTYLE;
            margin-top: 1em;
            grid-column: 1/3;
          }
          p,
          .asset-details {
            @include P_FONTSTYLE;
            grid-column: 1/3;
          }
          .sgt-resources-category-collection-item-downloadlink {
            @include TEXT_DOWNLOAD_LINK;
            margin-top: 1.5em;
            grid-column: 1/3;
            display: block;
          }
        }
      }
    }
  }
}

// old download stuff
.sgt-illustratedteaser {
  a {
    cursor: pointer;
  }
}

.sgt-downloads {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.chinesePart {
  width: fit-content;
  margin: 40px auto 100px auto;
}

.sgt-resources-old {
  @include PAGE_GRID;
  p {
    @include P_FONTSTYLE;
    grid-column: 2/14;
  }
  &-filter {
    @include PAGE_GRID;
    @include SECTION_CONTENT_SPACE(padding-top);
    grid-column: 1/15;
    .h2 {
      @include H2_FONTSTYLE;
      grid-column: 2/14;
    }
    p {
      @include P_FONTSTYLE;
      grid-column: 2/14;
    }
    ul {
      @include SECTION_SMALL_CONTENT_SPACE(padding-top);
      @include SECTION_LARGE_CONTENT_SPACE(padding-bottom);
      @include PAGE_MARGIN_IF_NOT_USING_GRID(padding-left, padding-right);
      box-sizing: border-box;
      grid-column: 1/15;
      width: 100%;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
      grid-template-columns: 0 repeat(12, 1fr) 0;
      li {
        display: inline-block;
        &:first-child {
          margin-left: 0;
        }
        a {
          display: inline-block;
          @include FOOTNOTE_FONTSTYLE;
          text-decoration: none !important;
          border-radius: 5em;
          padding: 0.3em 1.3em 0.3em 1.3em;
          color: $BLUE_COLOR;
          border: 1px solid $BLUE_COLOR;
          background-color: transparent;
          display: flex;
          &:hover,
          &:active {
            background-color: $LIGHT_GREEN_COLOR;
          }

          > * {
            display: flex;
            flex-direction: column;
            justify-content: center;
            &:last-child {
              margin-left: 10px;
            }
          }
          &.selected {
            padding: 0.3em 0.7em 0.3em 1.3em;
            color: $DARK_GREEN_COLOR;
            border: 1px solid $DARK_GREEN_COLOR;
            background-color: $LIGHT_GREEN_COLOR;
            &:hover,
            &:active {
              background-color: $BOLD_BLUE_COLOR;
            }
          }
        }
      }
    }
  }
}
