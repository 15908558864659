.sand-section {
  background-color: $SAND_COLOR;
  padding: 70px 0;
}

.expert-list {
  display: grid;
  grid-column: 2/8;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;

  @media (max-width: 1024px) {
    grid-column: 3/14;
    grid-template-columns: repeat(3, 1fr);
    order: 1;
  }

  @media (min-width: 992px) and (max-width: 1024px) {
    grid-column: 2/14;
  }

  @media (max-width: 992px) {
    grid-column: 2/11;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-column: 2/11;
    grid-template-columns: 1fr;
  }

  .expert-name-desc {
    @media (min-width: 576px) {
      width: 250px;
    }

    .expert-desc {
      margin-top: 10px;
    }
  }
}

.experts-description {
  grid-column: 8/14;

  @media (min-width: 992px) and (max-width: 1200px) {
    grid-column: 9/15;
  }

  @media (max-width: 992px) {
    grid-column: 2/14;
  }

  .btn {
    margin: 40px 0;
  }
  .experts-description-paragraphs {
    @media (min-width: 1024px) {
      width: 80%;
    }

    p {
      margin: 30px 0;
    }
  }
}
