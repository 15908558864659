@mixin CUSTOM_LIST_ICONS {
  position: relative;
  &:before {
    position: absolute;
    content: " ";
    width: 25px;
    height: 25px;
    top: 1px;
    left: -33px;
  }
  &[data-icontype="numbers"] {
    &:nth-child(1) {
      &:before {
        background-image: url("/assets/icons/list-number1-softred.svg");
      }
    }
    &:nth-child(2) {
      &:before {
        background-image: url("/assets/icons/list-number2-softred.svg");
      }
    }
    &:nth-child(3) {
      &:before {
        background-image: url("/assets/icons/list-number3-softred.svg");
      }
    }
    &:nth-child(4) {
      &:before {
        background-image: url("/assets/icons/list-number4-softred.svg");
      }
    }
  }
  &.check {
    &:before {
      background-image: url("/assets/icons/list-check-softgreen.svg");
    }
    &.blue {
      &:before {
        background-image: url("/assets/icons/list-check-softblue.svg");
      }
    }
    &.orange {
      &:before {
        background-image: url("/assets/icons/list-check-orange.svg");
      }
    }
    &.red {
      &:before {
        background-image: url("/assets/icons/list-check-softred.svg");
      }
    }
    &.purple {
      &:before {
        background-image: url("/assets/icons/list-check-purple.svg");
      }
    }
  }
  &.questionmark {
    &:before {
      background-image: url("/assets/icons/list-questionmark-softgreen.svg");
    }
    &.blue {
      &:before {
        background-image: url("/assets/icons/list-questionmark-softblue.svg");
      }
    }
    &.orange {
      &:before {
        background-image: url("/assets/icons/list-questionmark-orange.svg");
      }
    }
    &.red {
      &:before {
        background-image: url("/assets/icons/list-questionmark-softred.svg");
      }
    }
  }
  &.arrow {
    &:before {
      background-image: url("/assets/icons/list-arrow-softgreen.svg");
    }
    &.blue {
      &:before {
        background-image: url("/assets/icons/list-arrow-softblue.svg");
      }
    }
    &.orange {
      &:before {
        background-image: url("/assets/icons/list-arrow-orange.svg");
      }
    }
    &.red {
      &:before {
        background-image: url("/assets/icons/list-arrow-softred.svg");
      }
    }
    &.violet {
      &:before {
        background-image: url("/assets/icons/list-arrow-violet.svg");
      }
    }
    &.purple {
      &:before {
        background-image: url("/assets/icons/list-arrow-purple.svg");
      }
    }
  }
}

ul.custom-icons > li {
  @include CUSTOM_LIST_ICONS;
}
ul.points {
  li {
    margin-top: 20px;
  }
  li::before {
    content: "\2022"; /*  \2022 is the CSS Code/unicode for a bullet */
    color: $DEEP_BLUE_100_COLOR;
    font-family: "Siemens Sans Black";
    display: inline-block;
    width: 1em;
  }

  &.green li::before {
    color: #00d7a0;
  }
}
