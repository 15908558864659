.width37 {
  width: 37%;
}

.width50 {
  width: 50%;
}

.width75 {
  width: 75%;
}

.width100 {
  width: 100%;
}

.padding-top20 {
  padding-top: 20px;
}

.padding-top50 {
  padding-top: 50px;
}

.padding-bottom50 {
  padding-bottom: 50px;
}

.padding-bottom20 {
  padding-bottom: 20px;
}

.petrol-color {
  color: $SIEMENS_PETROL_100_COLOR;
}

.valid-color {
  color: $FEEDBACK_GREEN;
}

.unknown-color {
  color: $DEEP_BLUE_40_COLOR;
}

.invalid-color {
  color: $FEEDBACK_RED;
}

.dialog-dimensions {
  width: 50%;
  height: 80%;
}

.sand-background {
  background-color: $SAND_COLOR;
}

.hover-item {
  &:hover {
    background-color: $SAND_COLOR_HOVER;
    color: $HOVER_LINK_COLOR;
  }
  cursor: pointer;
}

.status-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: $DISABLED_COLOR;
  height: 1rem;
  width: 6rem;
  font-size: 1rem;
  border-radius: 10px;
  padding: 2px;
}

.status-bar.active {
  background-color: $FEEDBACK_GREEN;
}

.toggle-bg {
  height: 1rem;
  width: 2rem;
  background-color: $DISABLED_COLOR;
  border-radius: 10px;
  position: relative;
  padding: 2px;

  .toggle {
    width: 1rem;
    height: 1rem;
    background-color: white;
    position: absolute;
    border-radius: 10px;
    box-shadow: 2px 2px 12px #00000050;
  }
}

.toggle-on {
  background-color: $FEEDBACK_GREEN;
}

.admin-min-height {
  min-height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: flex-start;

  main {
    padding: 40px;
    width: 100%;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 40px;
  }
}

.admin-dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-gap: 20px;
}

.admin-link-container {
  height: 200px;
  width: 200px;
  background-color: $SAND_COLOR;
  padding: 10px;
  border: 4px solid transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px;
  box-sizing: border-box;
  user-select: none;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: $SAND_COLOR_HOVER;
    color: $HOVER_LINK_COLOR;
  }

  h4 {
    font-weight: bold;
  }

  .background-icon {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 70px;
  }

  &.disabled {
    background-color: $DISABLED_COLOR;
    color: $WHITE_COLOR;

    &:hover {
      background-color: $DISABLED_COLOR;
      color: $WHITE_COLOR;
      cursor: default;
    }
  }
}

.admin-heading {
  font-size: 30px;
}

.no-underline {
  &:hover {
    text-decoration: none;
  }
}

.admin-table {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 10px;
  font-size: 1rem;

  th {
    padding: 10px 20px;
  }

  tbody {
    tr {
      background-color: $SAND_COLOR;
      &:hover {
        background-color: $BRIGHT_SAND_COLOR;
      }
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  td {
    padding: 10px 20px;
  }

  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .edit-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  .mouse-pointer {
    cursor: pointer;
  }
}

.admin-dialog {
  .dialog-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .heading {
    font-size: 1.1em;
    font-weight: bold;
  }

  hr {
    border: 1px solid $GREY;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  p {
    font-size: 16px;
    margin: 10px;
  }

  .input-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .roles-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
  }

  .action-area {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    label {
      font-weight: initial;
      cursor: pointer;
    }
  }
}

.add-user-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: $SIEMENS_PETROL_100_COLOR;

  button {
    background: none;
    border: none;
    font-size: 0.8em;
    font-weight: bold;
    color: $SIEMENS_PETROL_100_COLOR;
    cursor: pointer;
  }
}

.user-sort-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;

  img {
    height: 40px;
    width: 40px;
  }
}

.translations-expand-container {
  margin-block: 10px !important;

  .mat-expansion-panel-header {
    padding: 0 10px;
  }

  .mat-expansion-panel-body {
    padding: 0 5px 16px;
  }

  .value-input {
    width: 80%;
  }

  .content {
    padding: 0 10px;
  }

  .toggle-original {
    margin-left: 20px;
    cursor: pointer;
    user-select: none;
    color: $LINK_COLOR;
    font-size: 12px;
    font-weight: 900;
  }
}

.translation-edit-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .key-name {
    max-width: 25%;
    min-width: 100px;
    overflow-wrap: anywhere;
  }

  .original-translation {
    max-width: 25%;
    color: $DEEP_BLUE_60_COLOR;
    overflow-wrap: break-word;
  }

  .mat-form-field-infix {
    background-color: #ebf7f8;
    border: 1px solid #ccccd4;
    height: auto;
    padding: 10px 8px !important;
  }

  .icon-Trash {
    font-size: 18px;
    width: 35px !important;
    height: 35px !important;

    &:hover {
      color: $HOVER_LINK_COLOR;
    }
  }

  .mouse-pointer {
    cursor: pointer;
  }
}

.admin-back-button {
  button {
    background-color: $DEEP_BLUE_40_COLOR;
    border: none;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    color: $DEEP_BLUE_100_COLOR;
    cursor: pointer;
    font-size: 14px;
    gap: 10px;
  }

  button:hover {
    background-color: $DEEP_BLUE_60_COLOR;
    color: white;
  }

  .back-icon {
    transform: rotate(90deg);
    font-size: 35px;
    margin-left: -10px;
    margin-right: -10px;
  }
}

.translations-update-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px;
  gap: 40px;

  .stage-container {
    width: 50%;

    h4 {
      font-size: 20px;
    }

    h6 {
      font-size: 16px;
    }
  }
}

.select-language {
  text-align: center;
}

.add-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;
}

.mat-expansion-panel-admin {
  .mat-expansion-panel-header {
    background-color: $SAND_COLOR;
  }

  &:hover {
    background-color: $SAND_COLOR_HOVER;
  }

  .mat-expansion-panel-header-title {
    color: $DEEP_BLUE_100_COLOR;
  }

  &.mat-expanded {
    box-shadow: 0px 1px 4px 2px #d7d2d2 !important;
    background-color: $SAND_COLOR_HOVER;
  }

  .mat-expanded {
    background-color: $SAND_COLOR_HOVER;

    .mat-expansion-panel-header-title {
      color: $HOVER_LINK_COLOR;
      font-size: 16px;
    }
  }
}

.language-selector {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 25px;

  li {
    display: flex;
    cursor: pointer;
    opacity: 0.5;
    position: relative;
    box-shadow: 2px 4px 5px #00000082;

    .prod-banner {
      position: absolute;
      top: 5px;
      right: -11px;
      background-color: #009999;
      margin: 0;
      padding: 5px;
      font-size: 14px;
      font-family: "Siemens Sans Black";
      color: white;
      line-height: 10px;
      border-radius: 5px;
    }
  }

  li:hover {
    opacity: 0.8;
    box-shadow: none;
  }

  .active {
    opacity: 1;
    box-shadow: none;
  }
}

.country-flag {
  width: 60px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.justify-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.btn-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .btn {
    display: block;
  }
}

.filter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.search {
  position: relative;

  .icon {
    position: absolute;
    top: 13px;
    right: 13px;
  }

  input {
    height: 44px;
    border-radius: 9999px;
    border: 1px solid $DEEP_BLUE_60_COLOR;
    min-width: 200px;
    padding: 10px;
    padding-right: 35px;
    outline: none;
  }

  input:focus {
    border-color: $SIEMENS_PETROL_100_COLOR;
  }

  input:focus-visible {
    border-color: $SIEMENS_PETROL_100_COLOR;
    outline: none !important;
  }
}

.language-entry {
  background-color: $SAND_COLOR;
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.language-entry:hover {
  background-color: $SAND_COLOR_HOVER;
}
