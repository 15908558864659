.calendar-section {
  width: 100%;
  background-color: $DEEP_BLUE_100_COLOR;
  padding: 60px 0;
  .text-container {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 0 repeat(12, 1fr) 0;
    @include RESPOND_FROM($BREAKPOINT_400) {
      grid-template-columns: 5px repeat(17, 1fr) 5px;
    }
    @include RESPOND_FROM($BREAKPOINT_1024) {
      grid-template-columns: 30px repeat(17, 1fr) 30px;
    }
    @include RESPOND_FROM($BREAKPOINT_1440) {
      grid-template-columns: 1fr repeat(17, 65px) 1fr;
    }
    .h1 {
      @include H1_FONTSTYLE;
      grid-column: 2/19;
      color: $WHITE_COLOR;
    }
    p {
      @include P_FONTSTYLE;
      max-width: 650px;
      color: $WHITE_COLOR;
      grid-column: 2/19;
    }
  }

  .calendar {
    margin-top: 40px;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 0 repeat(12, 1fr) 0;
    grid-template-rows: repeat(6, 100px);
    @include RESPOND_FROM($BREAKPOINT_400) {
      grid-template-columns: 5px repeat(17, 1fr) 5px;
      grid-gap: 5px;
    }
    @include RESPOND_FROM($BREAKPOINT_1024) {
      grid-template-columns: 30px repeat(17, 1fr) 30px;
      grid-gap: 5px;
    }
    @include RESPOND_FROM($BREAKPOINT_1440) {
      grid-template-columns: 1fr repeat(17, 65px) 1fr;
      grid-gap: 15px;
    }
    .calendar__item {
      //border: 1px solid white;
      background-color: $DEEP_BLUE_100_COLOR;
      background-repeat: no-repeat;
      cursor: pointer;
      &:hover {
        transform: scale(1.8);
        border-radius: 20px;
        z-index: 9;
      }
    }
  }
}

.calendar22-container,
.calendar23-container {
  background-color: $DEEP_BLUE_100_COLOR;
  @include PAGE_GRID;
  @include SECTION_CONTENT_SPACE(padding-top);
  @include SECTION_CONTENT_SPACE(padding-bottom);

  .img-container,
  .h2,
  .subheader,
  p {
    grid-column: 2/14;
    color: white;
  }
}

.calendar22Dialog,
.calendar23Dialog {
  .mdc-dialog__container {
    background-color: $DEEP_BLUE_100_COLOR;
    color: white;
  }
  .mat-mdc-dialog-container {
    --mdc-dialog-container-color: $DEEP_BLUE_100_COLOR;
  }
}
