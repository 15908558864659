.sgt-myGrowth-home-teasers {
  @include PAGE_GRID;
  @include SECTION_SPACE(padding-top);
  @include SECTION_SPACE(padding-bottom);
  background-color: $SAND_COLOR;
  .h2 {
    @include H2_FONTSTYLE;
    grid-column: 2/14;
    display: inline-block;
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 2/12;
    }
  }
  ul {
    @include SECTION_CONTENT_SPACE;
    @include PAGE_GRID;
    grid-column: 1/15;
    li {
      grid-column: 2/14;
      line-height: 0;
      @include PAGE_GRID_GAP(margin-top);
      @include RESPOND_FROM($BREAKPOINT_550) {
        &:nth-child(3n + 1) {
          grid-column: 2 / span 6;
        }
        &:nth-child(3n + 2) {
          grid-column: 8 / span 6;
        }
        &:nth-child(3n + 3) {
          grid-column: 2 / span 6;
        }
      }
      @include RESPOND_FROM($BREAKPOINT_768) {
        @include USE_3_COLUMNS;
      }
      img {
        width: 100%;
        height: 275px;
        object-fit: cover;
        position: relative;
        background-color: $DEEP_BLUE_20_COLOR;
        //   @include FLUID_PROPERTY(height, 450, 350, $BREAKPOINT_400, $BREAKPOINT_550);
        //   @include RESPOND_FROM($BREAKPOINT_550) {
        //       height: 400px;
        //   }
        //   @include RESPOND_FROM($BREAKPOINT_768) {
        //       @include FLUID_PROPERTY(height, 350, 550, $BREAKPOINT_768);
        //   }
      }
      div {
        box-sizing: border-box;
        .h3 {
          @include H3_FONTSTYLE;
          height: 68px;
        }
        p {
          margin-top: 20px;
          @include P_FONTSTYLE;
          height: 140px;
          overflow: auto;
        }
        a {
          @include FLUID_PROPERTY(margin-top, 20, 30);
          @include EXTERNAL_TEXT_LINK;
          color: #00819c;
        }
      }
    }
  }
}
