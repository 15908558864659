.sgt-tilesList {
  @include PAGE_GRID;
  @include SECTION_SPACE(padding-top);
  @include SECTION_SPACE(padding-bottom);

  &[data-layout="cards"] {
    &[data-theme="dark"] {
      background-color: $WHITE_COLOR;
      ul {
        li {
          background: $DEEP_BLUE_100_COLOR;
        }
      }
      .h3,
      p {
        color: $WHITE_COLOR;
      }
      a,
      .textLink {
        color: $DARK_LINK;
        &:hover {
          color: $DARK_LINK_HOVER;
          text-decoration: underline;
        }
      }
      .desc {
        color: $DEEP_BLUE_100_COLOR;
      }
    }
    &[data-theme="sand"] {
      background-color: $WHITE_COLOR;
      ul {
        li {
          background: $SAND_COLOR;
        }
      }
      .h3,
      p {
        color: $DEEP_BLUE_100_COLOR;
      }
    }
    .h2 {
      @include H2_FONTSTYLE;
      grid-column: 2/14;
      @include RESPOND_FROM($BREAKPOINT_768) {
        grid-column: 2/12;
      }
    }
    p {
      grid-column: 2/12;
    }
    ul {
      @include PAGE_GRID;
      @include SECTION_LARGE_CONTENT_SPACE;
      @include PAGE_GRID_GAP(row-gap);
      grid-column: 1/15;

      li {
        // background: $DEEP_BLUE_100_COLOR;
        box-sizing: border-box;
        position: relative;
        grid-column: 2/ 14;
        border-radius: 17px;
        @include FLUID_PROPERTY_ADVANCED(padding-left, 25, 25, 25, 50);
        @include FLUID_PROPERTY_ADVANCED(padding-right, 25, 25, 25, 50);
        padding-top: 50px;
        padding-bottom: 50px;

        @include RESPOND_UNTIL($BREAKPOINT_768) {
          &:nth-child(2n + 1) {
            border-bottom-right-radius: 0;
          }
          &:nth-child(2n + 2) {
            border-bottom-left-radius: 0;
          }
        }

        @include RESPOND_FROM($BREAKPOINT_768) {
          &:nth-child(2n + 1) {
            grid-column: 2 / span 6;
          }
          &:nth-child(2n) {
            grid-column: 8 / span 6;
          }
          &:nth-child(4n + 1) {
            border-bottom-right-radius: 0;
          }
          &:nth-child(4n + 2) {
            border-bottom-left-radius: 0;
          }
          &:nth-child(4n + 3) {
            border-top-right-radius: 0;
          }
          &:nth-child(4n + 4) {
            border-top-left-radius: 0;
          }
        }
        img {
          position: absolute;
          max-height: 120px;
          @include FLUID_PROPERTY_ADVANCED(width, 120, 130, 120, 170);
          @include FLUID_PROPERTY_ADVANCED(right, 0, 0, 0, 20);
          @include FLUID_PROPERTY_ADVANCED(top, 0, 0, 10, 20);
        }
        .h3 {
          @include H1_FONTSTYLE;
          @include FLUID_PROPERTY(font-size, 32, 40);
          @include FLUID_PROPERTY(line-height, 38, 44);
          //  color: $WHITE_COLOR;
          position: relative;
          width: calc(100% - 120px);
          overflow: hidden;
          // @include FLUID_PROPERTY(height, 90, 130, $BREAKPOINT_1024);
          @include RESPOND_UNTIL($BREAKPOINT_768) {
            height: auto;
          }
        }
        .largeTitle {
          @include FLUID_PROPERTY_ADVANCED(font-size, 20, 27, 20, 40);
        }
        p {
          @include FLUID_PROPERTY(margin-top, 20, 30);
          @include P_FONTSTYLE;
          overflow: hidden;
          // @include FLUID_PROPERTY(height, 250, 220, $BREAKPOINT_1024);
          @include RESPOND_UNTIL($BREAKPOINT_768) {
            height: auto;
          }
          //  color: $WHITE_COLOR;
        }
      }
    }

    ul.inTile {
      margin: 0;
      display: block;
      li {
        padding: 20px 0 0 40px;
      }
    }

    gt-custom-link {
      margin-top: 30px;
      display: block;
    }
  }

  &[data-layout="columns"] {
    background-color: $DEEP_BLUE_100_COLOR;
    .h2 {
      @include H2_FONTSTYLE;
      color: $WHITE_COLOR;
      grid-column: 2/14;
      @include RESPOND_FROM($BREAKPOINT_768) {
        grid-column: 2/12;
      }
    }
    ul {
      @include PAGE_GRID;
      @include SECTION_LARGE_CONTENT_SPACE;
      grid-column: 1/15;
      li {
        box-sizing: border-box;
        grid-column: 2/14;
        @include SECTION_LARGE_CONTENT_SPACE;
        @include RESPOND_FROM($BREAKPOINT_768) {
          padding-right: 1em;
          @include USE_2_COLUMNS;
        }
        @include RESPOND_FROM($BREAKPOINT_1024) {
          padding-right: 2em;
          @include USE_4_COLUMNS;
        }
        img {
          background-color: $DEEP_BLUE_80_COLOR;
          border-radius: 100%;
          display: block;
          margin: auto;
          @include FLUID_PROPERTY(width, 150, 150, $BREAKPOINT_400, $BREAKPOINT_768);
          @include RESPOND_FROM($BREAKPOINT_768) {
            @include FLUID_PROPERTY(width, 120, 195, $BREAKPOINT_768);
            margin: 0;
          }
        }
        .h3 {
          @include SECTION_CONTENT_SPACE;
          @include H3_FONTSTYLE;
          width: 100%;
          color: $WHITE_COLOR;
          text-align: center;
          @include RESPOND_FROM($BREAKPOINT_768) {
            text-align: left;
          }
        }
        p {
          @include SECTION_CONTENT_SPACE;
          @include P_FONTSTYLE;
          color: $WHITE_COLOR;
          max-width: 350px;
        }
      }
    }

    &[data-theme="sand"] {
      background-color: $WHITE_COLOR;
      ul {
        li {
          img {
            background: $SAND_COLOR;
          }
        }
      }
      .h3,
      .h2,
      p {
        color: $DEEP_BLUE_100_COLOR;
      }
    }
  }
}
