.sgt-people-hightlight-quote {
  @include PAGE_GRID;
  @include SECTION_CONTENT_SPACE(padding-top);
  position: relative;

  .content {
    @include SECTION_SMALL_CONTENT_SPACE;
    position: relative;
    z-index: 2;
    grid-column: 2/14;
    box-sizing: border-box;
    p {
      @include SECTION_SMALL_CONTENT_SPACE;
      @include QUOTE_AUTHOR_FONTSTYLE;
      max-width: 650px;
    }
    blockquote {
      @include QUOTE_FONTSTYLE;
      margin-top: 40px;
    }
  }
  figure {
    position: relative;
    z-index: 1;
    line-height: 0;
    align-self: center;
    text-align: center;
    img {
      @include RESPOND_FROM($BREAKPOINT_1024) {
        width: 100%;
      }
      @include RESPOND_UNTIL($BREAKPOINT_1024) {
        width: 50%;
      }
    }
  }

  &[data-scheme="dark"] {
    background-color: $DEEP_BLUE_100_COLOR;
    .content {
      p {
        color: $WHITE_COLOR;
      }
      blockquote {
        color: $WHITE_COLOR;
      }
    }
  }
  &[data-scheme="sand"] {
    background-color: $SAND_COLOR;
    .content {
      p {
        color: $DEEP_BLUE_100_COLOR;
      }
    }
  }

  &[data-illustration-mobile-position="below-desc"] {
    @include RESPOND_UNTIL($BREAKPOINT_550) {
      figure {
        grid-column: 2 / 14;
        @include SECTION_LARGE_CONTENT_SPACE;
      }
    }
  }
  &[data-image-position-when-next-to-copy="left"] {
    @include RESPOND_FROM($BREAKPOINT_1024) {
      figure {
        order: 1;
        &[data-size="normal"] {
          grid-column: 2/6;
        }
        &[data-size="reducedForPageNavigation"] {
          grid-column: 2/6;
        }
      }
      .content {
        @include SECTION_SPACE(margin-bottom);
        grid-column: 7/14;
        order: 2;
      }
    }
    @include RESPOND_FROM($BREAKPOINT_1440) {
      figure {
        &[data-size="reducedForPageNavigation"] {
          grid-column: 2/6;
        }
      }
      .content {
        grid-column: 7/14;
      }
    }
  }
  &[data-image-position-when-below-copy="right"] {
    @include RESPOND_FROM($BREAKPOINT_550) {
      figure {
        position: relative;
        grid-column: 2/13;
        width: 100%;
        top: unset;
        right: unset;
      }
      .content {
        @include SECTION_SPACE(margin-bottom);
      }
    }
  }
}
