/******************************************************************
 * UTILS
 *
 *
 *****************************************************************/

/* ----------------------------------------------------------------
 * Functions
 * --------------------------------------------------------------*/

@function getResponsiveValueCalc($minValue, $maxValue, $minWidth: $BREAKPOINT_400, $maxWidth: $BREAKPOINT_1440) {
  @return calc(#{$minValue}px + #{$maxValue - $minValue} * (100vw - #{$minWidth}px) / #{$maxWidth - $minWidth});
}

/* ----------------------------------------------------------------
 * Mixins
 * --------------------------------------------------------------*/

@mixin FLUID_PROPERTY($property, $minValue, $maxValue, $minBaseWidth: $BREAKPOINT_400, $maxBaseWidth: $BREAKPOINT_1440) {
  #{$property}: #{$minValue}px;
  @include RESPOND_FROM($minBaseWidth) {
    #{$property}: getResponsiveValueCalc($minValue, $maxValue, $minBaseWidth, $maxBaseWidth);
  }
  @include RESPOND_FROM($maxBaseWidth) {
    #{$property}: #{$maxValue}px;
  }
}

@mixin FLUID_PROPERTY_ADVANCED($property, $minValueSmall, $maxValueSmall, $minValueLarge, $maxValueLarge) {
  @include FLUID_PROPERTY($property, $minValueSmall, $maxValueSmall, $BREAKPOINT_400, $BREAKPOINT_768);
  @include RESPOND_FROM($BREAKPOINT_768) {
    @include FLUID_PROPERTY($property, $minValueLarge, $maxValueLarge, $BREAKPOINT_768);
  }
}

@mixin RESPOND_FROM($breakpoint) {
  @media (min-width: #{$breakpoint}px) {
    @content;
  }
}

@mixin RESPOND_UNTIL($breakpoint) {
  @media (max-width: #{$breakpoint - 1}px) {
    @content;
  }
}
