.hint {
  border: 1px solid transparent;
  border-radius: 12px;
  display: flex;
  padding: 15px;
  text-align: left;
  max-width: fit-content;
  @include SECTION_CONTENT_SPACE;

  &[data-scheme="orange"] {
    border-color: $ORANGE_100_COLOR;
  }

  &[data-scheme="blue"] {
    border-color: $SOFT_BLUE_100_COLOR;
  }

  &[data-scheme="green"] {
    border-color: $SOFT_GREEN_100_COLOR;
  }

  .imgContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
  }

  img {
    width: 56px;
    height: 56px;
    border-radius: 100%;
    margin: 0;
  }

  .title {
    @include SUBTITLE_FONTSTYLE;
    font-size: 16px;
    color: $DEEP_BLUE_60_COLOR;
    text-transform: none !important;
  }

  p {
    @include P_FONTSTYLE;
    line-height: 25px !important;
    color: $DEEP_BLUE_60_COLOR;
    margin: 0;
  }
}
