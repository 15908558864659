.time {
  font-size: 16px;
  display: flex;

  > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .icon {
    color: $DARK_GREEN_COLOR;
    font-size: 20px;
    margin-right: 10px;
    padding-bottom: 3px;
  }
}

p > a {
  color: $LINK_COLOR;
  text-decoration: none;
  &:hover {
    color: $HOVER_LINK_COLOR;
    text-decoration: none;
  }
}

.bb-textarea {
  border: 1.5px solid $DEEP_BLUE_20_COLOR;
  color: #4a4a59;
  width: 95%;
  height: 90%;
  padding: 16px;
  font-family: "Siemens Sans";
  font-size: 16px;

  &.wizard {
    margin-top: 25px;
  }
  &::-webkit-input-placeholder {
    color: #4a4a59;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: #4a4a59;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #4a4a59;
  }

  &:-ms-input-placeholder {
    color: #4a4a59;
  }

  &::placeholder {
    color: #4a4a59;
  }
}

.brandbuilder-home {
  background-color: $SAND_COLOR;
  min-height: 80vh;
  img {
    max-height: 500px;
    width: auto !important;
  }
}

.my-statement {
  margin-top: 20px;
  border-radius: 4px;
  background-color: $WHITE_COLOR;
  &.sand {
    background-color: $SAND_COLOR;
  }

  .content {
    min-height: 160px;
    padding: 24px;
  }

  .actions {
    border-top: 1px solid #ccccd4;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;

    a {
      margin-top: 0;
    }
  }
}

.brandbuilder-start {
  background-color: $SAND_COLOR;

  .steps {
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
  }

  .step {
    width: 30%;

    img {
      border-radius: 100%;
      width: 90%;
    }

    .title {
      height: 56px;
    }

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  @include RESPOND_UNTIL($BREAKPOINT_768) {
    .steps {
      flex-direction: column;

      .step {
        display: flex;
        margin-bottom: 20px;
        width: 100%;
      }

      .content {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      img {
        width: auto;
        max-width: 30%;
      }

      .title {
        height: auto;
      }

      .textLink {
        margin-top: 10px;
      }
    }
  }
}

.brandbuilder-wizard {
  min-height: calc(100vh - 100px);
  @include PAGE_GRID;

  .subtitle {
    @include SUBTITLE_FONTSTYLE();
  }

  .sidenav {
    grid-column: 2/4;
  }

  .container {
    grid-column: 5/14;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .category {
    @include PAGE_GRID;
    margin-top: 72px;
  }

  .content {
    grid-column: 1/10;
  }

  .illustration {
    grid-column: 10/13;
  }

  .btns-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 50px;

    > * {
      margin-right: 10px;
      min-width: auto;
    }
  }

  ul:not(.navigation):not(.trait-list) {
    margin-top: 20px;

    li::before {
      content: "\2022";
      color: $SOFT_GREEN_100_COLOR;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
}

.brandbuilder-overview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap;

  .overview_item {
    width: 150px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .title {
    font-size: 16px;
  }

  .img-container {
    position: relative;
  }

  .overview_badge_container {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 5px;
    right: 0;
  }
}

.bb-badge {
  width: 100%;
  height: 100%;
  background-color: $DARK_GREEN_COLOR;
  border-radius: 100%;
  color: $WHITE_COLOR;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 10px;
}

.trait-container {
  display: flex;
  margin-top: 30px;
  font-size: 16px;
  line-height: 20px;

  .traits,
  .comment {
    width: 60%;
    margin-right: 30px;
  }

  .trait {
    border: 1px solid $DEEP_BLUE_20_COLOR;

    .icon {
      color: $SOFT_GREEN_100_COLOR;
    }
  }

  .selection {
    padding: 16px;
    flex: 1;
    background-color: $SAND_COLOR;
    max-width: 300px;
    height: fit-content;

    .trait-placeholder {
      height: fit-content;
      justify-content: space-between;
      padding: 14px 16px;
      display: flex;
      min-height: 30px;
      border: 1px solid $DEEP_BLUE_20_COLOR;

      > * {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .icon {
        font-weight: bold;
        cursor: pointer;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &.filled {
        background-color: $WHITE_COLOR;
        border: none;

        .icon-Question {
          color: $SOFT_GREEN_100_COLOR;
          font-size: 24px;
          font-weight: normal;
        }
      }
    }
  }
}

.bb-statement-builder {
  font-size: 22px;

  .statement-container {
    background-color: $SAND_COLOR;
    margin-top: 20px;
  }

  .statement {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 24px;
    > * {
      margin-bottom: 10px;
    }

    .statement-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 10px;
    }

    .statement-item-small-margin {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 5px;
    }
  }

  .edit {
    border: 1px solid $DEEP_BLUE_20_COLOR !important;
    font-size: 22px;
    padding: 10px;

    .icon {
      margin-left: 4px;
    }
  }

  .controls {
    margin-top: 30px;
    border-top: 1px solid $DEEP_BLUE_20_COLOR;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .paginator {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .icon {
      cursor: pointer;

      &:first-of-type {
        margin-right: 20px;
      }

      &:last-of-type {
        margin-left: 20px;
      }
    }
  }
}

.bb-dialog-backdrop {
  background: #000028;
}

.bb-dialog {
  max-height: 90vh;
  min-width: 670px;

  .mdc-dialog__container {
    border-radius: 0 !important;
    padding: 0 !important;
  }
}

.bb-dialog-container {
  padding: 24px;

  .header {
    font-family: "Siemens Sans Black";
    font-size: 24px;

    > i {
      cursor: pointer;
    }
  }

  .headline {
    margin-top: 25px;
    display: flex;
    font-size: 16px;

    > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .icon {
      color: $SOFT_GREEN_100_COLOR;
      cursor: pointer;
      margin-left: 5px;
      font-size: 20px;
    }
  }

  .adjective-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .adjective {
      border: 1px solid $DEEP_BLUE_20_COLOR;
      padding: 14px 16px;
      font-size: 16px;
      margin-right: 10px;
      margin-top: 16px;
      cursor: pointer;
      display: flex;

      > * {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &.selected {
        background: $DYNAMIC_PETROL_GRADIENT;
        border: none;
        font-family: "Siemens Sans Black";

        .icon {
          font-weight: 800;
          margin-left: 10px;
        }
      }

      &.disabled {
        color: $DEEP_BLUE_40_COLOR;
        background-color: $SAND_COLOR;
        border: none;
        pointer-events: none;
      }
    }
  }

  .notes-container {
    margin-top: 25px;
    border-top: 1px solid $DEEP_BLUE_100_COLOR;
    border-bottom: 1px solid $DEEP_BLUE_100_COLOR;
    padding: 32px 0;
    font-size: 18px;

    .title {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .content {
      margin-top: 20px;
    }
  }

  .expertise-container {
    .input-container {
      display: flex;

      > *:last-child {
        margin-left: 20px;
      }
    }
  }

  .statement-container {
    position: relative;

    textarea {
      margin-top: 25px;
      font-size: 22px;
      line-height: 32px;
      color: $DEEP_BLUE_100_COLOR;
      min-height: 200px;
      width: 94%;
    }

    .word-count {
      position: absolute;
      bottom: 15px;
      right: 20px;
      font-size: 16px;
      color: $DEEP_BLUE_40_COLOR;
    }
  }
}

.bb-dialog-action-btns {
  margin-top: 30px;
  border-top: 1px solid $BRIGHT_SAND_COLOR;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div:last-of-type {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }

  .btn:last-child {
    margin-left: 15px;
  }
}
