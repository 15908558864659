.partners-slides {
  @include SECTION_SPACE(margin-bottom);
  background-color: $SAND_COLOR;

  .tabs {
    background-color: $WHITE_COLOR;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 30px;

    .tab {
      background-color: $DEEP_BLUE_20_COLOR;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      cursor: pointer;
      margin: 0 5px;

      &:hover,
      &.active {
        background-color: $DEEP_BLUE_40_COLOR;
      }
    }
  }
}
