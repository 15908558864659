.sgt-externalteasers {
  @include PAGE_GRID;
  @include SECTION_SPACE(padding-top);
  @include SECTION_SPACE(padding-bottom);
  .h2 {
    @include H2_FONTSTYLE;
    grid-column: 2/14;
    display: inline-block;
    @include RESPOND_FROM($BREAKPOINT_768) {
      grid-column: 2/12;
    }
  }
  ul {
    @include SECTION_CONTENT_SPACE;
    @include PAGE_GRID;
    grid-column: 1/15;
    li {
      position: relative;
      grid-column: 2/14;
      line-height: 0;
      box-shadow:
        0 1px 1px rgba(0, 0, 0, 0.04),
        0 2px 2px rgba(0, 0, 0, 0.08),
        0 4px 4px rgba(0, 0, 0, 0.12),
        0 8px 8px rgba(0, 0, 0, 0.08),
        0 16px 16px rgba(0, 0, 0, 0.04);
      @include PAGE_GRID_GAP(margin-top);
      @include RESPOND_FROM($BREAKPOINT_550) {
        &:nth-child(3n + 1) {
          grid-column: 2 / span 6;
        }
        &:nth-child(3n + 2) {
          grid-column: 8 / span 6;
        }
        &:nth-child(3n + 3) {
          grid-column: 2 / span 6;
        }
      }
      @include RESPOND_FROM($BREAKPOINT_768) {
        @include USE_3_COLUMNS;
      }
      &:after {
        position: absolute;
        content: " ";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 5.5e-5) 0%, rgba(0, 0, 0, 0.55) 100%, rgba(0, 0, 0, 0.55) 100%);
      }
      img {
        width: 100%;
        object-fit: cover;
        position: relative;
        @include FLUID_PROPERTY(height, 450, 350, $BREAKPOINT_400, $BREAKPOINT_550);
        @include RESPOND_FROM($BREAKPOINT_550) {
          height: 400px;
        }
        @include RESPOND_FROM($BREAKPOINT_768) {
          @include FLUID_PROPERTY(height, 350, 550, $BREAKPOINT_768);
        }
      }
      div {
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        box-sizing: border-box;
        padding: 2em 1.5em;
        @include FLUID_PROPERTY(padding-left, 35, 52, $BREAKPOINT_320, $BREAKPOINT_550);
        @include RESPOND_FROM($BREAKPOINT_550) {
          padding: 2em;
          @include FLUID_PROPERTY(padding-left, 35, 52, $BREAKPOINT_550);
        }
        .h3 {
          @include H3_FONTSTYLE;
          color: $WHITE_COLOR;
          position: relative;
          &:before {
            position: absolute;
            content: " ";
            width: 3px;
            height: calc(100% - 0.4em);
            top: 0.2em;
            background: $DYNAMIC_PETROL_GRADIENT;
            @include FLUID_PROPERTY(left, -12, -17, $BREAKPOINT_320, $BREAKPOINT_550);
            @include RESPOND_FROM($BREAKPOINT_550) {
              @include FLUID_PROPERTY(left, -12, -17, $BREAKPOINT_550);
            }
          }
        }
        a {
          @include SECTION_SMALL_CONTENT_SPACE;
          @include EXTERNAL_TEXT_LINK;
        }
      }
    }
  }
}
